import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { setFinishedContent, getContentsByChapter } from '../../utils/api'
import { setContentFinished } from '../../reducers/user';
import '../../../node_modules/overlayscrollbars/css/OverlayScrollbars.css';
import OverlayScrollbars from '../../../node_modules/overlayscrollbars/js/OverlayScrollbars.js';
import { Redirect } from 'react-router-dom';
import UnauthorizedCore from '../../components/unauthorized/UnauthorizedCore';
import Html from '../../components/courseViewer/Html';

class CourseViewer extends Component {
    constructor(props) {
        super(props);

        const finisheds = props.contents ? props.contents.filter((content) => content.finished).length : 1;

        this.state = {
            loading: false,
            indexSelected: 0,
            eChaptersNames: {
                sa89s8a9sc: 'Desafios das Gerações',
                sa89s8a9sb: 'Antes de Começar',
                sa89s8a9sd: 'Forças de Caráter',
                sa89s8a9se: 'Biblioteca',
                sa89s8a9sa: 'Desafios do Futuro'
            },
            eChaptersList: ['sa89s8a9sb', 'sa89s8a9sa', 'sa89s8a9sc', 'sa89s8a9sd', 'sa89s8a9se'],
            contentSelected: {},
            percentFinisheds: props.contents ? parseInt(finisheds * 100 / props.contents.length) : 0,
            contents: props.contents,
            menuMobileOpen: false
        };
    }

    componentDidMount() {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 200);
        const isMobile = document.documentElement.clientWidth < 991;
        var instance = OverlayScrollbars(document.getElementById(isMobile ? "scrollbar-mobile" : "scrollbar-web"), {
            scrollbars: {
                visibility: "auto",
                autoHide: "move",
                autoHideDelay: 800,
                dragScrolling: true,
                clickScrolling: false,
                touchSupport: true,
                snapHandle: false
            }
        });

        if (!this.props.contents) {
            getContentsByChapter(this.props.match.params.code)
                .then((result) => {
                    const contents = result.contents;
                    if (this.props.user.finishedsContents)
                        contents.map((content) => {
                            content.finished = this.props.user.finishedsContents.includes(content.code)
                        });

                    const contentSelected = !this.props.match.params.content ? contents[0] : contents.filter((ct) => ct.code === this.props.match.params.content)[0];
                    const finisheds = contents ? contents.filter((content) => content.finished).length : 0;

                    window.history.pushState({}, "", `/chapter/${this.props.match.params.code}/${contentSelected.code}`);

                    this.setState({
                        contentSelected,
                        contents,
                        indexSelected: contents.indexOf(contentSelected),
                        percentFinisheds: parseInt(finisheds * 100 / contents.length)
                    });
                });
        }
    }

    setFinishedContent = (content) => {
        if (!content.finished) {
            setFinishedContent(content.code, this.props.uid);
            this.props.setContentFinished(content.code);
        }

        ReactGA.pageview(window.location.pathname + window.location.search);

        ReactGA.event({
            category: 'Contents',
            action: 'Viewed content',
            label: content.name,
            nonInteraction: true
        });

        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
    }

    handleSelectContent = (e) => {
        e.preventDefault();
        let { indexSelected } = this.state;
        const previousContent = this.state.contents[indexSelected];
        this.setFinishedContent(previousContent);


        const contents = this.state.contents.map((content, index) => {
            if (indexSelected === index)
                content.finished = true;
            return content
        });

        const contentSelected = this.state.contents.filter((content, index) => {
            if (content.code === e.target.id) {
                indexSelected = index;
                return true;
            } else
                return false;
        })[0];

        const finisheds = contents ? contents.filter((content) => content.finished).length : 0;

        window.history.pushState({}, "", `/chapter/${this.props.match.params.code}/${contentSelected.code}`);

        this.setState({
            contentSelected,
            indexSelected,
            contents,
            percentFinisheds: parseInt(finisheds * 100 / contents.length),
            menuMobileOpen: false
        });
    }

    handleNextContent = (e) => {
        e.preventDefault();
        let { indexSelected } = this.state;
        const previousContent = this.state.contents[indexSelected];
        this.setFinishedContent(previousContent);

        const contents = this.state.contents.map((content, index) => {
            if (indexSelected === index)
                content.finished = true;
            return content;
        });

        indexSelected = this.state.indexSelected + 1;
        const contentSelected = this.state.contents[indexSelected];

        const finisheds = contents ? contents.filter((content) => content.finished).length : 0;

        window.history.pushState({}, "", `/chapter/${this.props.match.params.code}/${contentSelected.code}`);

        this.setState({
            contentSelected,
            indexSelected,
            contents,
            percentFinisheds: parseInt(finisheds * 100 / contents.length)
        });
    }


    handleNextCourse = (e) => {
        e.preventDefault();
        let { indexSelected } = this.state;

        if (!this.state.contents || !indexSelected)
            return;

        const previousContent = this.state.contents[indexSelected];
        this.setFinishedContent(previousContent);

        let index = 0;
        this.state.eChaptersList.forEach((e, i) => {
            if (e === this.props.match.params.code)
                index = i;
        });

        window.location.pathname = `/chapter/${this.state.eChaptersList[index + 1]}`;
    }

    handleMenuClick = (e) => {
        e.preventDefault();

        const menuMobileOpen = !this.state.menuMobileOpen;
        this.setState({ menuMobileOpen });
    }

    toPath = (e) => {
        e.preventDefault();

        this.props.history.push(e.currentTarget.id);
    }

    goBack = (e) => {
        const isLoggedUser = this.props.user !== undefined;

        if (isLoggedUser) {
            const { indexSelected } = this.state;
            const previousContent = this.state.contents[indexSelected];
            this.setFinishedContent(previousContent);
            this.toPath(e);
        }
    }

    getNextContentRoute = () => {
        let { indexSelected } = this.state;
        return '/' + this.props.match.params.code + '/' + this.state.contents[indexSelected + 1].code;
    }

    getContentRoute = (index) => {
        return '/' + this.props.match.params.code + '/' + this.state.contents[index].code;
    }

    getNextCourseRoute = () => {
        let index = 0;
        this.state.eChaptersList.forEach((e, i) => {
            if (e === this.props.match.params.code)
                index = i;
        });

        return '/' + this.state.eChaptersList[index + 1];
    }

    handleSelectCourse = (e) => {
        window.location.pathname = `/chapter/${e.target.value}`;
    }

    render() {
        const { contentSelected, indexSelected, contents, percentFinisheds, menuMobileOpen, eChaptersNames } = this.state;
        const isLoggedUser = this.props.user !== undefined && this.props.user.uid !== undefined;
        const isUnauthorized = (!isLoggedUser || (this.props.user.plan === 'none' && this.props.user.expired)) && contentSelected.freeContent !== true && contentSelected && contentSelected.code != undefined;

        return (
            <main className="player">
                <div id="scrollbar-web" className="player-menu div-block" style={{ display: menuMobileOpen ? 'flex' : '', position: 'fixed' }}>
                    <div id="scrollbar-mobile" className="player-menu-container">
                        <div className="player-cover">
                            <div className="player-cover-shaddow">
                                <div className="player-nav">
                                    <a href={isLoggedUser ? "/course/filhos21" : "https://www.itinere.com.br"} className="player-nav-link w-inline-block" id={isLoggedUser ? "/course/filhos21" : "https://www.itinere.com.br"}>
                                        <img src={require('../../assets/images/arrow-left.svg')} width="20" alt="" /><div class="player-nav-text back-menu">VOLTAR</div>
                                    </a>
                                    <div className="player-menu-close" data-ix="close-menu" onClick={this.handleMenuClick}></div>
                                </div>
                                <div className="player-cover-name">
                                    <div className="player-name">{eChaptersNames[this.props.match.params.code]}</div>
                                    <div className="player-progress-bar">
                                        <div className="plyaer-progress-bar-percent" style={{ width: percentFinisheds + '%' }}></div>
                                    </div>
                                    <div className="player-percet-text">{percentFinisheds}% CONCLUÍDO</div>
                                </div>
                            </div>
                        </div>
                        <div className="player-lesson-name">
                            <div>Minhas Aulas</div>
                        </div>
                        <div className="player-menu-list">
                            {
                                contents && this.state.contents.map((content, index) => {
                                    return <a key={content.code} href={this.getContentRoute(index)} className={"player-menu-item w-inline-block" + (content.code === contentSelected.code ? " selected" : "")} onClick={this.handleSelectContent} id={content.code}>
                                        <div className={"player-menu-item-icon" + (content.finished ? " viewed" : "") + (content.code === contentSelected.code ? " selected" : "")} id={content.code}></div>
                                        <div className="player-menu-item-title" id={content.code}>{index + 1}. {content.name} {content.freeContent && !isLoggedUser ? '(free)' : ''}</div>
                                        <div className="player-menu-item-time" id={content.code}>{content.time}</div>
                                    </a>
                                })
                            }
                        </div>
                        <div class="player-menu-form w-form">
                            <select value={this.props.match.params.code} id="field" name="field" class="input select w-select" onChange={this.handleSelectCourse}>
                                <option value="sa89s8a9sb">Antes de Começar</option>
                                <option value="sa89s8a9sa">Desafios do Futuro</option>
                                <option value="sa89s8a9sc">Desafios das Gerações</option>
                                <option value="sa89s8a9sd">Forças de Caráter</option>
                                <option value="sa89s8a9se">Biblioteca</option>
                            </select>
                        </div>
                        <div class="player-menu-form w-form">
                            <a href={isLoggedUser ? "/course/filhos21" : "https://www.itinere.com.br"} className="purple" id={isLoggedUser ? "/course/filhos21" : "https://www.itinere.com.br"}>
                                Voltar para a página do curso
                            </a>
                        </div>
                    </div>
                </div>
                <div className="player-header">
                    <div className="player-menu-icon" data-ix="open-menu" onClick={this.handleMenuClick}></div>
                    <img src={require('../../assets/images/itinere-white.svg')} alt="" className="player-header-logo" />
                </div>
                <div className="player-content">
                    <div className="player-content-head">
                        <div className="player-content-container">
                            <div className="player-content-head-icon"></div>
                            <h1 className="player-content-h1">{indexSelected + 1}. {contentSelected.name}</h1>
                            {
                                contents && contents.length - 1 !== indexSelected &&
                                <a href={this.getNextContentRoute(contentSelected.code)} className="btn btn-medium btn-next-content w-button" onClick={this.handleNextContent}><strong>PRÓXIMO</strong> CONTEÚDO</a>
                            }
                            {
                                !(contents && contents.length - 1 !== indexSelected) && this.props.match.params.code !== 'sa89s8a9se' &&
                                <a href={this.getNextCourseRoute()} className="btn btn-medium btn-next-content w-button" onClick={this.handleNextCourse}><strong>PRÓXIMO</strong> CONTEÚDO</a>
                            }
                        </div>
                    </div>
                    <div className="player-content-video">
                        {
                            isUnauthorized &&
                            <UnauthorizedCore />
                        }
                        {
                            (contentSelected.type === 'video' || contentSelected.type === 'html') && !isUnauthorized &&
                            <Html contentSelected={contentSelected} />
                        }
                        {
                            contents && contents.length - 1 !== indexSelected && !isUnauthorized &&
                            <a href="" className="btn btn-medium btn-next-content btn-responsive w-button" onClick={this.handleNextContent}><strong>PRÓXIMO</strong> CONTEÚDO</a>
                        }
                        {
                            !(contents && contents.length - 1 !== indexSelected) && this.props.match.params.code !== 'sa89s8a9se' && !isUnauthorized &&
                            <a href="" className="btn btn-medium btn-next-content btn-responsive w-button" onClick={this.handleNextCourse}><strong>PRÓXIMO</strong> CONTEÚDO</a>
                        }
                    </div>
                </div>
            </main>
        );
    }
}

function mapStateToProps(state, { navigation }) {
    return {
        user: state.user.user,
        uid: state.user.uid
    }
}

const mapDispatchToProps = dispatch => ({
    setContentFinished: contentCode => dispatch(setContentFinished(contentCode))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CourseViewer)