import React, { Component } from 'react';
import { getContentsByChapter } from '../../utils/api';
import { connect } from 'react-redux';
import {
    withRouter
} from 'react-router-dom';

class Contents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contents: [],
            loading: true
        }
    }

    componentWillMount() {
        getContentsByChapter(this.props.chapter)
            .then((result) => {
                this.setState({
                    contents: result.contents,
                    loading: false
                });
                this.props.setContentsLength(result.contents.length)
            });
    }

    toPath = (e) => {
        e.preventDefault();

        this.props.history.push(e.currentTarget.id);
    }

    render() {
        const { contents, loading } = this.state;

        return (
            <div className="course-detail-portlet-right">
                <ul className="course-detail-content-list">
                    {
                        !loading && contents && contents.map((content, index) => {
                            return (
                                <li className="course-detail-content-list-item" key={content.code}>
                                    <a href="" className="course-detail-content-link w-inline-block" id={"/chapter/" + this.props.chapter + "/" + content.code}
                                        onClick={this.toPath.bind(this)}>
                                        <div className="course-detail-content-item-icon"></div>
                                        <div className="course-detail-content-item-name">{index + 1}. {content.name}</div>
                                        <div className="course-detail-content-time">{content.time}</div>
                                    </a>
                                </li>
                            )
                        })
                    }
                </ul>
                {
                    loading &&
                    <div style={{ display: "flex", alignItems: "center", width: "100%", height: "100%" ,justifyContent: "center" }}>
                        <img src={require('../../assets/images/rolling.svg')} alt="" width="30px" height="30px" />
                    </div>
                }
            </div>
        );
    }
}

export default connect(
    null,
    null
)(withRouter(Contents))