import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import {
    withRouter
} from 'react-router-dom';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import Modal from '../../components/modal/Modal';
import { uploadPhoto, edit, updatePassword, logout, login } from '../../utils/api';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { setUser, setUid } from '../../reducers/user';
const CPF = require('cpf');

class MyData extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: this.props.user,
            hasDataChanges: false,
            hasPasswordChange: false,
            saving: false,
            savingPass: false,
            modalCreditOpenned: false,
            loadingPhoto: false
        };
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 200);
        let { user } = this.state;
        user.cpf = CPF.format(user.cpf);

        document.getElementById('name').value = user.displayName;
        document.getElementById('cpf').value = user.cpf;
        document.getElementById('email').value = user.email;
    }

    handleFileUpload = (e) => {
        e.preventDefault();
        document.getElementById("new-photo").click();


        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];

            var formData = new FormData();
            formData.append('file', file, file.name);

            this.setState({ loadingPhoto: true });

            uploadPhoto(formData)
                .then((data) => {
                    let { user } = this.state;
                    user.photoURL = data.file.Location;

                    edit(user)
                        .then(() => {
                            this.setState({ user, hasDataChanges: false, loadingPhoto: false });
                            this.props.setUser(user);
                            ToastsStore.success("Foto atualizada com sucesso!");
                        });
                });
        }
    }

    saveUser = (e) => {
        e.preventDefault();

        let user = {}
        user.name = document.getElementById('name').value;
        user.cpf = document.getElementById('cpf').value;
        user.email = document.getElementById('email').value;
        user.type = document.getElementById('type').value;

        if (!CPF.isValid(user.cpf)) {
            this.setState({ invalidCpf: true });
            return;
        }

        this.setState({ saving: true });

        edit(user)
            .then(() => {
                let userEdited = this.props.user;
                userEdited.displayName = document.getElementById('name').value;
                userEdited.cpf = document.getElementById('cpf').value;
                userEdited.email = document.getElementById('email').value;
                userEdited.type = document.getElementById('type').value;

                this.props.setUser(userEdited);
                this.setState({ hasDataChanges: false });
                ToastsStore.success("Dados alterados com sucesso!");
            })
            .catch((error) => {
                ToastsStore.error("Oops... Ocorreu algum erro ao tentar editar!");
            })
            .finally(() => {
                this.setState({ saving: false });
            });
    }

    savePassword = (e) => {
        e.preventDefault();

        const oldPassword = document.getElementById('oldPassword').value;
        const newPassword = document.getElementById('newPassword').value;
        const newPasswordConfirm = document.getElementById('newPasswordConfirm').value;

        if (newPasswordConfirm !== newPassword) {
            this.setState({ invalidPassword: true });
            return;
        } else
            this.setState({ invalidPassword: false, invalidPasswordNumber: false });

        this.setState({ savingPass: true });

        updatePassword(oldPassword, newPassword, this.state.user.email)
            .then(() => {
                document.getElementById('oldPassword').value = '';
                document.getElementById('newPassword').value = '';
                document.getElementById('newPasswordConfirm').value = '';

                ToastsStore.success("Senha atualizada com sucesso!");

                this.updateToken(this.state.user.email, newPassword);
            })
            .catch((error) => {
                if (error && error.code === "auth/wrong-password")
                    ToastsStore.error("Oops... Por favor, verifique sua senha atual!");
                else if (error && error.code === "auth/weak-password")
                    this.setState({ invalidPasswordNumber: true });
                else
                    ToastsStore.error("Oops... Algo deu errado, por favor tente novamente!");
            })
            .finally(() => {
                this.setState({ savingPass: false });
            });
    }

    updateToken = (email, senha) => {
        login(email, senha);
    }

    handleChange = (e) => {
        e.preventDefault();

        const { hasDataChanges, user } = this.state;
        const name = document.getElementById('name').value;
        const cpf = document.getElementById('cpf').value;
        const email = document.getElementById('email').value;
        const type = document.getElementById('type').value;

        if ((user.name !== name || user.cpf !== cpf || user.email !== email || user.type !== type) && hasDataChanges !== true)
            this.setState({
                hasDataChanges: true,
                invalidCpf: false
            });
        else if (hasDataChanges !== false)
            this.setState({
                hasDataChanges: true,
                invalidCpf: false
            });
    }

    signOut = () => {
        logout()
            .then(() => {
                this.props.history.push('/');
                this.props.setUser({});
                this.props.setUid({});
            });
    }

    goToRegisterSecoundStep = (e) => {
        e.preventDefault();
        this.props.history.push('/register', { step: 2 });
    }

    openChangeCreditCardModal = (e) => {
        e.preventDefault();
        this.setState({ modalCreditOpenned: true });

        let select = document.getElementById("selectChangeCredit");
        select.value = 'card';
    }

    closeCreditCardModal = (e) => {
        e.preventDefault();
        this.setState({ modalCreditOpenned: false });
    }

    render() {
        let { user, hasDataChanges, saving, savingPass, loadingPhoto } = this.state;

        return (
            <div>
                <Header breads={[{ name: 'Meus Dados' }]} />
                <div className="user">
                    <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
                    <div className="user-bg-left"></div>
                    <div className="container">
                        <div className="user-container">
                            <div className="user-main">
                                <h2 className="user-h2">Meus Dados</h2>
                                <div className="w-form">
                                    <form id="email-form" name="email-form" data-name="Email Form" className="form" onSubmit={this.saveUser}>
                                        <div className="user-form-container">
                                            <div className="user-form-container-left">
                                                <input required type="text" className="input w-input" maxLength="256" name="name-4" data-name="Name 4" placeholder="Seu Nome" id="name" onChange={this.handleChange} />
                                                <input required type="text" className="input w-input" maxLength="256" name="name-2" data-name="Name 2" placeholder="000.000.000-00" id="cpf" onChange={this.handleChange} />
                                                {
                                                    this.state.invalidCpf &&
                                                    <div className="error-message">
                                                        <div className="text-block"><strong>CPF inválido!</strong> <br />Por favor confira e tente novamente.</div>
                                                    </div>
                                                }
                                                <input required type="email" className="input w-input" maxLength="256" name="name-2" data-name="Name 2" placeholder="nome@email.com" id="email" onChange={this.handleChange} />
                                                <div className="user-two-columns">
                                                    <select id="type" name="type" data-name="type" className="input select profile w-select" onChange={this.handleChange}>
                                                        <option selected={(user.type === "father") ? "selected" : ""} value="father">Eu sou Pai</option>
                                                        <option selected={(user.type === "educator") ? "selected" : ""} value="educator">Eu sou Educador</option>
                                                        <option selected={(user.type === "responsible") ? "selected" : ""} value="responsible">Eu sou Responsável</option>
                                                        <option selected={(user.type === "other") ? "selected" : ""} value="other">Eu sou Outro</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="user-form-container-right">
                                                <div className="user-photo-border" >
                                                    {
                                                        loadingPhoto &&
                                                        <div className="user-photo" style={{
                                                            backgroundSize: '30px',
                                                            // backgroundColor: '#5d05d4',
                                                            backgroundImage: 'url(' + require('../../assets/images/rolling.svg') + ')'
                                                        }}>
                                                        </div>
                                                    }
                                                    {
                                                        !loadingPhoto &&
                                                        <div className="user-photo" style={{
                                                            backgroundImage: 'url(' + (user.photoURL !== undefined ? user.photoURL : require('../../assets/images/avatar.png')) + ')'
                                                        }}>
                                                            <div className="user-photo-btn-hold">
                                                                <input type="file" id="new-photo" onChange={this.handleFileUpload} style={{ display: 'none' }} accept=".png,.jpg,.jpeg,.jfif" />
                                                                <a href="" onClick={this.handleFileUpload} className="btn btn-photo-edit w-button">Editar</a>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="user-two-columns">
                                            <div className="user-two-columns">
                                                <input type="submit" value={saving ? "" : "Alterar dados"} className={saving ? "btn btn-login w-button btn-loading" : "btn btn-login w-button"} disabled={!hasDataChanges} />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <h2 className="user-h2">Alterar Senha</h2>
                                <div className="w-form">
                                    <form id="email-form" name="email-form" data-name="Email Form" className="form" onSubmit={this.savePassword}>
                                        <div className="user-two-columns">
                                            <input required id="oldPassword" type="password" className="input left w-input" maxLength="256" placeholder="Senha Atual" />
                                        </div>
                                        <div className="user-two-columns">
                                            <input required id="newPassword" type="password" className="input left w-input" maxLength="256" placeholder="Nova Senha" />
                                            <input required id="newPasswordConfirm" type="password" className="input right w-input" maxLength="256" placeholder="Confirmar Nova Senha" />
                                            {
                                                this.state.invalidPassword &&
                                                <div className="error-message">
                                                    <div className="text-block"><strong>Senhas diferentes!</strong> <br />Verifique se a nova senha e confirmação de senha são as mesmas.</div>
                                                </div>
                                            }
                                            {
                                                this.state.invalidPasswordNumber &&
                                                <div className="error-message">
                                                    <div className="text-block"><strong>Senhas inválida!</strong> <br />Por favor digite pelo menos 6 caracteres.</div>
                                                </div>
                                            }
                                        </div>
                                        <div className="user-two-columns">
                                            <div className="user-two-columns">
                                                <input type="submit" value={savingPass ? "" : "Alterar senha"} className={savingPass ? "btn btn-login w-button btn-loading" : "btn btn-login w-button"} />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="user-aside">
                                <div className="user-aside-panels">
                                    <div className="user-aside-panels-right">
                                        {
                                            user.plan === 'none' &&
                                            <div className="portlet portlet-specialist">
                                                <div className="user-aside-title"><strong>Assinatura</strong></div>
                                                <div className="text-block-2">Você não tem nenhum plano assinado</div>
                                                <br />
                                                <a href="" onClick={this.goToRegisterSecoundStep} type="button" className="btn w-button">
                                                    {
                                                        user.alreadySign ?
                                                            'Reativar assinatura' :
                                                            'Assinar Agora'
                                                    }
                                                </a>
                                            </div>
                                        }
                                        {
                                            user.signDaysLeft !== undefined && user.signDaysLeft > 0 &&
                                            <div className="portlet portlet-specialist">
                                                <div>
                                                    <div className="text-block-2">Você cancelou a assinatura e tem</div>
                                                    <div className="user-plan-price">{user.signDaysLeft}</div>
                                                    <div className="text-block-3">dias restantes</div>
                                                    {
                                                        user.creditCard &&
                                                        <Modal idModal="cancel" buttonClass="user-plan-cancel" buttonDesc="Cancelar Plano" type={"cancel"} />
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {
                                            user.plan !== 'none' &&
                                            <div className="portlet portlet-specialist">
                                                <div className="user-aside-title"><strong>Plano</strong> Atual</div>
                                                {
                                                    user.plan !== 'free' &&
                                                    <div>
                                                        <div className="text-block-2">O seu plano atual contratado é o <strong>MENSAL</strong>, por míseros:</div>
                                                        <div className="user-plan-price">76</div>
                                                        <div className="text-block-3">,80/ mês</div>
                                                        {/* <div className="user-plan-form w-form">
                                                         <form id="email-form-2" name="email-form-2" data-name="Email Form 2">
                                                             <select id="field-2" name="field-2" className="input select user-plan-select w-select">
                                                                 <option value="">Plano Mensal</option>
                                                             </select>
                                                         </form>
                                                     </div> */}
                                                        <Modal idModal="cancel" buttonClass="user-plan-cancel" buttonDesc="Cancelar Plano" type={"cancel"} />
                                                        {
                                                            user.coupon !== '' &&
                                                            <div className="text-block-2">Você tem <strong>{user.couponDiscount}% de desconto até {user.couponDeadLine}</strong>, por ter usado o cupom <strong>{user.coupon}</strong>.</div>
                                                        }
                                                    </div>
                                                }
                                                {
                                                    user.plan === 'free' &&
                                                    <div>
                                                        <div className="text-block-2">Você está usando o cupom <strong>{user.coupon}</strong> e tem mais:</div>
                                                        <div className="user-plan-price">{user.daysOfDiscount}</div>
                                                        <div className="text-block-3">dias grátis</div>
                                                        {
                                                            user.creditCard &&
                                                            <Modal idModal="cancel" buttonClass="user-plan-cancel" buttonDesc="Cancelar Plano" type={"cancel"} />
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {
                                            user.creditCard &&
                                            <div className="portlet portlet-specialist portlet-payment">
                                                <div className="user-aside-title">Meio de <strong>Pagamento</strong></div>
                                                <div className="text-block-2">Você está utilizando o cartão de crédito como meio de pagamento. Caso deseje utilizar outro cartão, clique abaixo:</div>
                                                <div className="user-plan-form w-form">
                                                    <form id="email-form-2" name="email-form-2" data-name="Email Form 2">
                                                        <select id="selectChangeCredit" name="selectChangeCredit" className="input select user-credit-card w-select" onChange={this.openChangeCreditCardModal}>
                                                            <option value="card">**** **** **** {user.creditCard}</option>
                                                            <option value="change">Trocar cartão</option>
                                                        </select>
                                                    </form>
                                                    {
                                                        this.state.modalCreditOpenned &&
                                                        <Modal idModal="change" descItem="Trocar cartão" type={"change"} modalOppened={this.state.modalCreditOpenned} closeCreditCardModal={this.closeCreditCardModal} />
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="user-bg-right"></div>
                </div>
                <Footer />
            </div>
        );
    }
}

function mapStateToProps(state, { navigation }) {
    return {
        user: state.user.user
    }
}

const mapDispatchToProps = dispatch => ({
    setUser: user => dispatch(setUser(user)),
    setUid: uid => dispatch(setUid(uid)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(MyData))