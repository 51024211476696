import React from 'react';
import Login from './views/login/Login';
import Dashboard from './views/dashboard/Dashboard';
import CourseViewer from './views/courseViewer/CourseViewer';
import CourseDetail from './views/courseDetail/CourseDetail';
import MyData from './views/myData/MyData';
import Register from './views/register/Register';
import Unauthorized from './views/unauthorized/Unauthorized';
import NotFound from './views/notFound/NotFound';
import Forum from './views/forum/Forum';
import ForumNew from './views/forumNew/ForumNew';
import ForumDetail from './views/forumDetail/ForumDetail';
import reducer from './reducers/index';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { checkUserStatus } from './utils/api';

import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
const store = createStore(reducer, applyMiddleware(thunk));

function App() {
  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route exact {...rest} render={props => (
      localStorage.getItem("token") ? (
        <Component {...props} />
      ) : (
          <Redirect to={{
            pathname: '/',
            state: { from: props.location }
          }} />
        )
    )} />
  );

  return (
    <Provider store={store}>
      <div className="App" style={{ height: '100%' }}>
        <Router>
          <Switch>
            <Route path="/" exact component={Login} />
            <Route path="/esqueci-minha-senha" exact component={Login} />
            <Route path="/register" component={Register} />
            <PrivateRoute path="/unauthorized" exact component={Unauthorized} />
            <PrivateRoute path="/dashboard" exact component={Dashboard} />
            <PrivateRoute path="/course/:code" exact component={CourseDetail} />
            <Route path="/chapter/:code" component={CourseViewer} />
            <PrivateRoute path="/mydata" component={MyData} />
            <PrivateRoute path="/forum" component={Forum} />
            <PrivateRoute path="/forum-new" component={ForumNew} />
            <PrivateRoute path="/forum-detail/:postId" component={ForumDetail} />
            <Route path="/chapter/:code/:content" component={CourseViewer} />
            <Route path="*" component={NotFound} />
          </Switch>
        </Router>
      </div >
    </Provider>
  );
}

export default App;
