import React, { Component } from 'react';
import {
    withRouter
} from 'react-router-dom';
import { connect } from 'react-redux';

class NormalPost extends Component {
    constructor() {
        super();
    }

    componentDidMount() {
    }

    toPath = (e) => {
        e.preventDefault();

        this.props.history.push({
            pathname: e.currentTarget.id,
            state: { post: this.props.post }
        });
    }

    getTimeAgo = () => {
        const year = this.props.post.date.split('-')[0];
        const month = parseInt(this.props.post.date.split('-')[1]) - 1;
        const day = this.props.post.date.split('-')[2];
        const date = new Date(year, month, day);
        const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

        const diffTime = Math.abs(date - today);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays === 0)
            return `hoje`
        else if (diffDays === 1)
            return `ontem`
        else
            return `há ${diffDays} dia(s)`
    }

    render() {
        return (
            <div className="portlet forum-post" key={this.props.post.code} id={'/forum-detail/' + this.props.post.code}
                onClick={this.toPath.bind(this)} style={{ width: '100%', cursor: 'pointer' }}>
                <div className="forum-post-content">
                    <div className="forum-post-tag" style={{ backgroundColor: this.props.post.course.color }}>{this.props.post.course.name}</div>
                    <div className="forum-post-title">{this.props.post.title}</div>
                    <div className="forum-post-description">{this.props.post.description.length > 215 ? (this.props.post.description.substring(0, 215) + '...') : this.props.post.description}</div>
                </div>
                <div className="forum-post-details">
                    <div className="forum-post-replies">
                        <div className="forum-post-replies-number">{this.props.post.replies.length}</div>
                        <div className="forum-post-replies-text">respostas</div>
                    </div>
                    <div className="forum-post-author">
                        <div className="forum-post-photo" style={{
                            backgroundImage: 'url(' + (this.props.post.user.photoURL !== undefined ? this.props.post.user.photoURL : require('../../assets/images/avatar.png')) + ')'
                        }}></div>
                        <div className="forum-post-info">
                            <div className="forum-post-author-name">{this.props.post.user.displayName}</div>
                            <div className="forum-post-author-since">{this.getTimeAgo()}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, { navigation }) {
    return {
    }
}

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(NormalPost))