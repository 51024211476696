import React, { Component } from 'react';
import Iugu from '././../../utils/iuguService';
import Inputmask from "inputmask";
import { createPaymentToken, createSign, getCouponValidation } from '../../utils/api';
import { connect } from 'react-redux';
import {
    withRouter
} from 'react-router-dom';
import { setUser, setUid } from '../../reducers/user';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { debounce } from 'throttle-debounce';
import Modal from '../../components/modal/Modal';

class StepTwo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saving: false,
            validating: false
        };
    }
    iugu = Iugu;

    componentDidMount() {
        var credit = document.getElementById("credit");
        Inputmask({ "mask": "9999 9999 9999 9999" }).mask(credit);

        var cvv = document.getElementById("cvv");
        Inputmask({ "mask": "999" }).mask(cvv);

        var valid = document.getElementById("valid");
        Inputmask({ "mask": "99/99" }).mask(valid);
    }

    signItinere = async (e) => {
        e.preventDefault();

        const couponCode = this.state.coupon ? this.state.coupon.code : undefined;

        if (!this.state.isFullDiscount) {
            if (!Iugu.utils.validateCreditCardNumber(document.getElementById('credit').value)) {
                this.setState({ invalidCreditCard: true });
                return;
            } else
                this.setState({ invalidCreditCard: false });

            if (!Iugu.utils.validateExpirationString(document.getElementById('valid').value)) {
                this.setState({ invalidValid: true });
                return;
            } else
                this.setState({ invalidValid: false });

            const cardBrand = Iugu.utils.getBrandByCreditCardNumber(document.getElementById('credit').value);

            if (!Iugu.utils.validateCVV(document.getElementById('cvv').value, cardBrand)) {
                this.setState({ invalidCvv: true });
                return;
            } else
                this.setState({ invalidCvv: false });

            let newToken = {}
            newToken.creditCard = document.getElementById('credit').value;
            newToken.cvv = document.getElementById('cvv').value;

            const name = document.getElementById('name').value
            const arrayName = name.split(' ').filter((partName, index) => (index > 0));
            let lastName = "";
            arrayName.forEach((name) => {
                lastName += " " + name;
            });

            newToken.firstName = name.split(' ')[0];
            newToken.lastName = lastName;

            const valid = document.getElementById('valid').value;
            newToken.month = valid.split('/')[0];
            newToken.year = valid.split('/')[1];

            this.setState({ saving: true });

            createPaymentToken(this.props.user.uid, newToken)
                .then(() => {
                    createSign(this.props.user.uid, couponCode)
                        .then((result) => {
                            if (result.success) {
                                this.props.setUser(result.user);
                                this.props.history.push('/dashboard');
                            }
                        })
                        .catch(() => {
                            this.setState({ saving: false });
                            ToastsStore.error("Não foi possível efetuar a cobrança, favor conferir os dados !");
                        });
                })
                .catch(() => {
                    this.setState({ saving: false });
                    ToastsStore.error("Não foi possível efetuar a cobrança, favor conferir os dados !");
                });
        }
        else {
            this.setState({ saving: true });

            createSign(this.props.user.uid, couponCode)
                .then((result) => {
                    if (result.success) {
                        this.props.setUser(result.user);
                        this.props.history.push('/dashboard');
                    }
                })
                .catch(() => {
                    this.setState({ saving: false });
                    ToastsStore.error("Não foi possível efetuar a assinatura, favor conferir os dados !");
                });
        }
    }

    handleBlurCreditCard = (e) => {
        e.preventDefault();

        if (!Iugu.utils.validateCreditCardNumber(document.getElementById('credit').value)) {
            this.setState({ invalidCreditCard: true });
            return;
        } else
            this.setState({ invalidCreditCard: false });
    }

    handleBlurValid = (e) => {
        e.preventDefault();

        if (!Iugu.utils.validateExpirationString(document.getElementById('valid').value)) {
            this.setState({ invalidValid: true });
            return;
        } else
            this.setState({ invalidValid: false });
    }

    handleBlurCvv = (e) => {
        e.preventDefault();

        const cardBrand = Iugu.utils.getBrandByCreditCardNumber(document.getElementById('credit').value);

        if (!Iugu.utils.validateCVV(document.getElementById('cvv').value, cardBrand)) {
            this.setState({ invalidCvv: true });
            return;
        } else
            this.setState({ invalidCvv: false });
    }

    handleChangeDiscount = () => {
        this.setState({ validating: true });

        const couponCode = document.getElementById('coupon').value;

        if (couponCode.trim() === '') {
            this.setState({ invalidCoupon: false, validating: false, coupon: undefined, isFullDiscount: undefined });
            return;
        }

        getCouponValidation(couponCode)
            .then((result) => {
                const isFullDiscount = result.coupon.discount === 100;
                if (result.couponIsValid)
                    this.setState({ invalidCoupon: false, coupon: result.coupon, isFullDiscount });
                else
                    this.setState({ invalidCoupon: true, message: result.justify });
            })
            .catch((error) => {
                this.setState({ invalidCoupon: true, message: "Por favor confira o cupom e tente novamente.", coupon: undefined, isFullDiscount: undefined });
            })
            .finally(() => {
                this.setState({ validating: false });
            });
    }

    handleCouponChange = debounce(500, () => {
        this.handleChangeDiscount();
    });

    render() {
        let { saving, validating, isFullDiscount } = this.state;
        return (
            <div className="signup-middle-container">
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
                <div className="signup-middle-left"></div>
                <div className="signup-middle-right">
                    <div className="signup-title">
                        <strong>Assine</strong> Itinere
                    </div>
                    <div className="signup-form w-form">
                        <form id="step-two-form" name="step-two-form" onSubmit={this.signItinere}>
                            <input type="text" className="input w-input" maxLength="256" name="coupon" placeholder="Cupom de Desconto" id="coupon" onChange={this.handleCouponChange} />
                            {
                                this.state.invalidCoupon &&
                                <div className="error-message">
                                    <div className="text-block"><strong>Cupom inválido!</strong> <br />{this.state.message}</div>
                                </div>
                            }
                            {
                                this.state.coupon &&
                                <div className="error-message">
                                    <div className="text-block-success"><strong>Cupom válido!</strong> <br />{this.state.coupon.message}</div>
                                </div>
                            }
                            <input type="text" className="input w-input" maxLength="256" name="credit" placeholder="Número do Cartão" id="credit" onBlur={this.handleBlurCreditCard} required={!isFullDiscount} disabled={isFullDiscount} />
                            {
                                this.state.invalidCreditCard && !isFullDiscount &&
                                <div className="error-message">
                                    <div className="text-block"><strong>Cartão inválido!</strong> <br />Por favor confira e tente novamente.</div>
                                </div>
                            }
                            <input type="text" className="input w-input" maxLength="256" name="name" placeholder="Nome impresso no cartão" id="name" required={!isFullDiscount} disabled={isFullDiscount} />
                            <div className="signup-two-columns">
                                <input type="text" className="input vencimento w-input" maxLength="256" name="valid" placeholder="Vencimento" id="valid" required={!isFullDiscount} disabled={isFullDiscount} onBlur={this.handleBlurValid} />
                                <input type="text" className="input cvv w-input" maxLength="256" name="cvv" placeholder="CVV" id="cvv" required={!isFullDiscount} disabled={isFullDiscount} onBlur={this.handleBlurCvv} />
                            </div>
                            {
                                this.state.invalidValid && !isFullDiscount &&
                                <div className="error-message">
                                    <div className="text-block"><strong>Data inválida!</strong> <br />Por favor confira a data de vencimento e tente novamente.</div>
                                </div>
                            }
                            {
                                this.state.invalidCvv && !isFullDiscount &&
                                <div className="error-message">
                                    <div className="text-block"><strong>CVV inválido!</strong> <br />Por favor confira o CVV e tente novamente.</div>
                                </div>
                            }
                            <select id="field" name="field" className="input plan select white w-select"><option value="">Plano Mensal - R$ 76,80/ mês</option></select>

                            <Modal idModal="term" buttonClass="signup-term w-inline-block" buttonDesc="Termo de Adesão" type={"term"} />

                            <input type="submit" value={saving ? "" : (validating ? "Validando cupom..." : "Assinar Itinere")} className={saving ? "btn btn-login w-button btn-loading" : "btn btn-login w-button"} disabled={saving || validating || this.state.invalidCoupon} />
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, { navigation }) {
    return {
        user: state.user.user
    }
}

const mapDispatchToProps = dispatch => ({
    setUser: user => dispatch(setUser(user)),
    setUid: uid => dispatch(setUid(uid))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(StepTwo))