import React, { Component } from 'react';
import ReactGA from 'react-ga';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import ForumNewCore from '../../components/forumNew/ForumNewCore';
import { connect } from 'react-redux';
import {
    withRouter
} from 'react-router-dom';

class ForumNew extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 200);
    }

    render() {
        return (
            <div>
                <Header breads={[{ name: 'Fórum de Discussão', link: '/forum' }, { name: 'Novo Tópico' }]} />
                <ForumNewCore />
                <Footer />
            </div>
        );
    }
}

function mapStateToProps(state, { navigation }) {
    return {
        user: state.user.user
    }
}

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ForumNew))