import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    withRouter
} from 'react-router-dom';

class ResetPasswordForm extends Component {
    constructor() {
        super();
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="login-form">
                <img src={require('../../assets/images/itinere-white.svg')} alt="" className="login-right-logo-white" />
                <img src={require('../../assets/images/itinere-logo.png')} alt="" className="login-right-logo" />
                <h1 className="login-heading">Esqueci <br /><span className="login-heading-light">Minha Senha</span></h1>
                <div className="login-form-block w-form">
                    <form id="email-form" name="email-form" data-name="Email Form" onSubmit={this.props.resetPassword.bind(this)}>
                        <p className="reset-pass">Preencha o campo abaixo para receber um e-mail com as instruções de alteração da senha:</p>
                        <input type="email" className="login-input-text w-input" autoFocus={true} maxLength="256" name="email" data-name="Email" placeholder="E-mail" id="email" required />
                        <input type="submit" value={this.props.loading ? " " : "Criar nova senha"} className={this.props.loading ? "btn btn-login w-button btn-loading" : "btn btn-login w-button"} />
                    </form>
                    {
                        this.props.invalidLogin &&
                        <div class="error-message">
                            <div class="text-block"><strong>E-mail não identificado!</strong><br />Por favor confira os dados e tente novamente.</div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, { navigation }) {
    return {
        invalidLogin: state.user.invalidLogin
    }
}

export default connect(
    mapStateToProps,
    null
)(withRouter(ResetPasswordForm))