import React, { Component } from 'react';
import { deleteReply } from '../../../utils/api';
import { connect } from 'react-redux';
import {
    withRouter
} from 'react-router-dom';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import '../Modal.css'

class DeletePost extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false
        };
    }

    componentDidMount() {
    }

    handleDeleteReply = (e) => {
        e.preventDefault();
        this.setState({ loading: true });

        deleteReply(this.props.reply.code)
            .then(() => {
                ToastsStore.success("REsposta apagada com sucesso !");
                setTimeout(() => {
                    document.location.reload();
                }, 800);
            })
            .catch(() => {
                ToastsStore.error("Não foi possível apagar o post!");
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        return (
            <div className="portlet-modal">
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
                <div className="portlet-modal-header">
                    <div className="portlet-modal-title">Apagar Resposta</div>
                    <div className="portlet-modal-close" style={{ cursor: "pointer" }} onClick={this.props.closeModal}></div>
                </div>
                <div className="portlet-modal-main">
                    <div>Você tem certeza que quer apagar esta resposta?</div>
                </div>
                {
                    !this.state.loading &&
                    <div className="portlet-modal-footer">
                        <a href onClick={this.handleDeleteReply} className="btn btn-small outline cancel w-button">Quero apagar</a>
                        <a href onClick={this.props.closeModal} className="btn btn-small w-button">Não quero apagar</a>
                    </div>
                }
                {
                    this.state.loading &&
                    <div className="portlet-modal-footer">
                        <input type="button" value="" disabled={true} className="btn btn-login w-button btn-loading" />
                    </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state, { navigation }) {
    return {
        user: state.user.user
    }
}

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DeletePost))