import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    withRouter
} from 'react-router-dom';

class CourseBanner extends Component {
    constructor() {
        super();
    }

    componentDidMount() {
    }

    toPath = (e) => {
        e.preventDefault();

        this.props.history.push(e.currentTarget.id);
    }

    render() {
        return (
            <section className="courser-detail-banner">
                <div className="container">
                    <div className="course-detail-banner-container">
                        <div className="course-detail-banner-image"></div>
                        <div className="course-detail-banner-content">
                            <div className="course-detail-banner-title">{this.props.title}</div>
                            <a href="" id="/chapter/sa89s8a9sb/asdasd888888" onClick={this.toPath.bind(this)} className="btn btn-start-trail w-button"><strong>Iniciar</strong> a Trilha</a>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default connect(
    null,
    null
)(withRouter(CourseBanner))