import React, { Component } from 'react';
import ReactGA from 'react-ga';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import UnauthorizedCore from '../../components/unauthorized/UnauthorizedCore';
import { connect } from 'react-redux';
import {
    withRouter
} from 'react-router-dom';

class Unauthorized extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 200);
    }

    render() {
        return (
            <div>
                <Header breads={[{ name: 'Trilhas', link: '/dashboard' }, { name: 'Filhos 2.1' }]} />
                <UnauthorizedCore />
                <Footer />
            </div>
        );
    }
}

function mapStateToProps(state, { navigation }) {
    return {
    }
}

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Unauthorized))