export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR';
export const SET_USER = 'SET_USER';
export const SET_UID = 'SET_UID';
export const SET_CONTENT_FINISHED = 'SET_CONTENT_FINISHED';

export function actionSetLoginError(invalidLogin) {
    return {
        type: SET_LOGIN_ERROR,
        invalidLogin
    }
}

export function actionSetUser(user) {
    return {
        type: SET_USER,
        user
    }
}

export function actionSetUid(uid) {
    return {
        type: SET_UID,
        uid
    }
}

export function actionSetContentFinished(contentCode) {
    return {
        type: SET_CONTENT_FINISHED,
        contentCode
    }
}