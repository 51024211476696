
import {
    SET_CHAPTER,
    REMOVE_CHAPTERS,
    actionSetChapter,
    actionRemoveChapters
} from '../actions/chapter'

const initialState = {};

export default function chapter(state = initialState, action) {
    const { chapter } = action;

    switch (action.type) {
        case SET_CHAPTER:
            {
                let chapters = state.chapters ? state.chapters : [];
                chapters.push(chapter);

                return {
                    ...state,
                    chapters
                }
            }
        case REMOVE_CHAPTERS:
            {
                let chapters = [];

                return {
                    ...state,
                    chapters
                }
            }
        default:
            return state
    }
}

export function setChapter(chapter) {
    return (dispatch) => {
        const action = actionSetChapter(chapter);
        dispatch(action);
    }
}

export function removeChapters() {
    return (dispatch) => {
        const action = actionRemoveChapters();
        dispatch(action);
    }
}