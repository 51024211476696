import React, { Component } from 'react';

export default class Welcome extends Component {
    constructor() {
        super();
    }

    componentDidMount() {
    }

    render() {
        return (
            <section className="dash-banner">
                <div className="container">
                    <div className="dash-banner-container">
                        <div className="dash-banner-image"></div>
                        <div className="dash-banner-content">
                            <div className="dash-banner-title">Seja bem-vindo ao Itinere!</div>
                            <p className="dash-banner-description">Esperamos que a sua experiência e a de sua família seja fantástica.</p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}