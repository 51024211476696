
import {
    SET_LOGIN_ERROR,
    SET_USER,
    SET_UID,
    SET_CONTENT_FINISHED,
    actionSetUser,
    actionSetLoginError,
    actionSetUid,
    actionSetContentFinished
} from '../actions/user'

const initialState = localStorage.getItem("state") ?
    JSON.parse(localStorage.getItem("state"))
    : {
        invalidLogin: false,
        user: {}
    };

export default function user(state = initialState, action) {
    const { invalidLogin, user, uid, contentCode } = action;

    switch (action.type) {
        case SET_LOGIN_ERROR:
            return {
                ...state,
                invalidLogin
            }
        case SET_USER:
            localStorage.setItem("state", JSON.stringify({
                ...state,
                user,
                invalidLogin: false
            }));

            return {
                ...state,
                user,
                invalidLogin: false
            }
        case SET_UID:
            localStorage.setItem("state", JSON.stringify({
                ...state,
                uid
            }));
            localStorage.setItem("uid", uid);

            return {
                ...state,
                uid
            }
        case SET_CONTENT_FINISHED:
            let userCached = JSON.parse(localStorage.getItem("state")).user;

            if (!userCached.finishedsContents || !(userCached.finishedsContents.indexOf(contentCode) > -1)) {
                if (userCached.finishedsContents)
                    userCached.finishedsContents.push(contentCode);
                else
                    userCached.finishedsContents = [contentCode];
            }

            localStorage.setItem("state", JSON.stringify({
                ...state,
                user: userCached
            }));

            return {
                ...state,
                user: userCached
            }
        default:
            return state
    }
}

export function setLoginError(invalidLogin) {
    return (dispatch) => {
        const action = actionSetLoginError(invalidLogin);
        dispatch(action);
    }
}

export function setUser(user) {
    return (dispatch) => {
        const action = actionSetUser(user);
        dispatch(action);
    }
}

export function setUid(uid) {
    return (dispatch) => {
        const action = actionSetUid(uid);
        dispatch(action);
    }
}

export function setContentFinished(contentCode) {
    return (dispatch) => {
        const action = actionSetContentFinished(contentCode);
        dispatch(action);
    }
}