import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import Welcome from '../../components/banner/Welcome';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false
        };
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 200);
    }

    toPath = (e) => {
        e.preventDefault();

        this.props.history.push(e.currentTarget.id);
    }

    render() {
        return (
            <div>
                <Header breads={[{ name: 'Dashboard' }]} />
                <Welcome />
                <main className="dash-main">
                    <div className="container">
                        <div className="dash-main-container">
                            <div className="dash-title">Trilhas <strong>Disponíveis</strong></div>
                            <a href="" id="/course/filhos21" className="dash-trail-box w-inline-block" onClick={this.toPath.bind(this)}>
                                <div className="dash-trail-img"></div>
                                <div className="dash-trail-box-right">
                                    <div className="dash-trail-content">
                                        <h2 className="dash-trail-h2">Filhos 2.1: criando <br />filhos no século 21</h2>
                                        <div className="dash-trail-description">
                                            As mudanças de gerações e as principais características que envolvem cada período. Os desafios do futuro frente às mudanças. Abordagem sobre as forças de caráter e como os pais podem construir condições para que os filhos desenvolvam suas capacidades.
                                        </div>
                                    </div>
                                    <div className="dash-trail-courses">
                                        <div className="dash-trail-courses-head">Cursos da Trilha</div>
                                        <div className="dash-course-item">
                                            <div className="dash-course-icon">
                                                <div>01</div>
                                            </div>
                                            <div className="dash-course-text">
                                                <div className="dash-course-name">Desafios do Futuro</div>
                                            </div>
                                        </div>
                                        <div className="dash-course-item">
                                            <div className="dash-course-icon">
                                                <div>02</div>
                                            </div>
                                            <div className="dash-course-text">
                                                <div className="dash-course-name">Desafios das Gerações</div>
                                            </div>
                                        </div>
                                        <div className="dash-course-item">
                                            <div className="dash-course-icon">
                                                <div>03</div>
                                            </div>
                                            <div className="dash-course-text">
                                                <div className="dash-course-name">Forças de Caráter</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <div className="dash-title next-trail">Próximas <strong>Trilhas</strong></div>
                            <a className="dash-trail-box text-red w-inline-block">
                                <div className="dash-trail-img comunicacao-saudavel"></div>
                                <div className="dash-trail-box-right">
                                    <div className="dash-trail-content">
                                        <h2 className="dash-trail-h2 text-red">Comunicação saudável entre pais e filhos</h2>
                                        <div className="dash-trail-description">
                                        Convivência sadia e a construção de valores morais na família. Prevenção e resolução não violenta de conflitos, fundamentada nos princípios da tolerância, solidariedade e respeito.
                                        </div>
                                    </div>
                                    <div className="dash-trail-courses">
                                        <div className="dash-trail-courses-head">Cursos da Trilha</div>
                                        <div className="dash-course-item">
                                            <div className="dash-course-icon red">
                                                <div>01</div>
                                            </div>
                                            <div className="dash-course-text">
                                                <div className="dash-course-name">Tamo junto: convivência e empatia</div>
                                            </div>
                                        </div>
                                        <div className="dash-course-item">
                                            <div className="dash-course-icon red">
                                                <div>02</div>
                                            </div>
                                            <div className="dash-course-text">
                                                <div className="dash-course-name">Sem graç@: bullying e cyberbullying</div>
                                            </div>
                                        </div>
                                        <div className="dash-course-item">
                                            <div className="dash-course-icon red">
                                                <div>03</div>
                                            </div>
                                            <div className="dash-course-text">
                                                <div className="dash-course-name">Diz aí: comunicação não-violenta</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a className="dash-trail-box text-green w-inline-block">
                                <div className="dash-trail-img produtividade"></div>
                                <div className="dash-trail-box-right">
                                    <div className="dash-trail-content">
                                        <h2 className="dash-trail-h2 text-green">Produtividade na mira</h2>
                                        <div className="dash-trail-description">
                                        Técnicas e estratégias para aprimorar a produtividade nos estudos. Orientações práticas e específicas para que os pais ajudem as crianças e adolescentes a se organizarem melhor.    
                                        </div>
                                    </div>
                                    <div className="dash-trail-courses">
                                        <div className="dash-trail-courses-head">Cursos da Trilha</div>
                                        <div className="dash-course-item">
                                            <div className="dash-course-icon blue">
                                                <div>01</div>
                                            </div>
                                            <div className="dash-course-text">
                                                <div className="dash-course-name">Organização do tempo e estudo</div>
                                            </div>
                                        </div>
                                        <div className="dash-course-item">
                                            <div className="dash-course-icon blue">
                                                <div>02</div>
                                            </div>
                                            <div className="dash-course-text">
                                                <div className="dash-course-name">Aprender é fácil: princípios de um estudo eficiente</div>
                                            </div>
                                        </div>
                                        <div className="dash-course-item">
                                            <div className="dash-course-icon blue">
                                                <div>03</div>
                                            </div>
                                            <div className="dash-course-text">
                                                <div className="dash-course-name">Estratégias práticas e os truques de aprendizado</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </main>
                <Footer />
            </div>
        );
    }
}

function mapStateToProps(state, { navigation }) {
    return {}
}

const mapDispatchToProps = dispatch => ({})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard)