import React, { Component } from 'react';
import { cancelSign } from '../../../utils/api';
import { connect } from 'react-redux';
import {
    withRouter
} from 'react-router-dom';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { setUser } from '../../../reducers/user';
import '../Modal.css'

class CancelAccount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false
        };
    }

    componentDidMount() {
    }

    cancelAccount = (e) => {
        e.preventDefault();
        this.setState({ loading: true });

        cancelSign(this.props.user.uid)
            .then((result) => {
                if (result.success) {
                    this.props.setUser(result.user);
                }
                ToastsStore.success("Assinatura cancelada com sucesso !");
                setTimeout(() => {
                    document.location.reload();
                }, 800);
            })
            .catch(() => {
                ToastsStore.error("Não foi possível efetuar o cancelamento !");
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        return (
            <div className="portlet-modal">
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
                <div className="portlet-modal-header">
                    <div className="portlet-modal-title">Cancelamento de Plano</div>
                    <div className="portlet-modal-close" style={{ cursor: "pointer" }} onClick={this.props.closeModal}></div>
                </div>
                <div className="portlet-modal-main">
                    <div>Você tem certeza que deseja cancelar o plano e deixar de acessar o Itinere?</div>
                </div>
                {
                    !this.state.loading &&
                    <div className="portlet-modal-footer">
                        <a href onClick={this.cancelAccount} className="btn btn-small outline cancel w-button">Quero cancelar</a>
                        <a href onClick={this.props.closeModal} className="btn btn-small w-button">Não quero cancelar</a>
                    </div>
                }
                {
                    this.state.loading &&
                    <div className="portlet-modal-footer">
                        <input type="button" value="" disabled={true} className="btn btn-login w-button btn-loading" />
                    </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state, { navigation }) {
    return {
        user: state.user.user
    }
}

const mapDispatchToProps = dispatch => ({
    setUser: user => dispatch(setUser(user))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CancelAccount))