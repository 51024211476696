import React, { Component } from 'react';
import './Modal.css';
import ChangeCreditCard from './modalContent/ChangeCreditCard';
import CancelAccount from './modalContent/CancelAccount';
import AdhesionTerm from './modalContent/AdhesionTerm';
import DeletePost from './modalContent/DeletePost';
import DeleteReply from './modalContent/DeleteReply';
import Depoiment from './modalContent/Depoiment';

export default class Modal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            reload: false
        }
    }

    componentDidMount() {
        var modal = document.getElementById(this.props.idModal);
        var btn = document.getElementById('btn' + this.props.idModal);

        if (btn)
            btn.onclick = function (e) {
                e.preventDefault();
                modal.style.display = "block";
            }

        const closeCreditCardModal = this.props.closeCreditCardModal;

        const isDepoiment = this.props.type === "depoiment";
        const closeDepoimentModal = this.props.closeDepoimentModal;

        window.onclick = function (event) {
            if (event.target == modal) {
                modal.style.display = "none";
                if (isDepoiment)
                    closeDepoimentModal(event);
            }
        }
    }

    closeModal = (e) => {
        const isDepoiment = this.props.type === "depoiment";
        if (isDepoiment)
            this.props.closeDepoimentModal(e);

        var modal = document.getElementById(this.props.idModal);
        modal.style.display = "none";
    }

    openModal = () => {
        setTimeout(() => {
            var modal = document.getElementById(this.props.idModal);
            modal.style.display = "block";
        }, 100);
    }

    render() {
        if (this.props.modalOppened)
            this.openModal();

        return (
            <div>
                <div id={this.props.idModal} className="modal">
                    <div style={{ position: "absolute", display: "flex", alignItems: "center", width: "100%", justifyContent: "center" }}>
                        {
                            this.props.type === "cancel" &&
                            <CancelAccount closeModal={this.closeModal} />
                        }
                        {
                            this.props.type === 'change' &&
                            <ChangeCreditCard closeModal={this.closeModal} />
                        }
                        {
                            this.props.type === 'term' &&
                            <AdhesionTerm closeModal={this.closeModal} />
                        }
                        {
                            this.props.type === 'deletePost' &&
                            <DeletePost closeModal={this.closeModal} post={this.props.post} />
                        }
                        {
                            this.props.type === 'deleteReply' &&
                            <DeleteReply closeModal={this.closeModal} reply={this.props.reply} />
                        }
                        {
                            this.props.type === 'depoiment' &&
                            <Depoiment closeModal={this.closeModal} />
                        }
                    </div>
                </div>
                {
                    this.props.type !== "change" && this.props.type !== "depoiment" &&
                    <a href="" id={'btn' + this.props.idModal} className={this.props.buttonClass}>{this.props.buttonDesc}</a>
                }
            </div>
        );
    }
}