import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    withRouter
} from 'react-router-dom';
import { sendPost } from '../../utils/api';

class ForumNewCore extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saving: false
        }
    }

    componentDidMount() {
    }

    doPost = async (e) => {
        e.preventDefault();
        this.setState({ saving: true });

        const post = {
            title: document.getElementById('title').value,
            description: document.getElementById('description').value,
            courseCode: document.getElementById('course').value,
            userCpf: this.props.user.cpf
        }

        sendPost(post)
            .then(() => {
                this.props.history.push("/forum");
            })
            .catch(() => {
                this.setState({ saving: false });
            });
    }

    render() {
        return (
            <main className="forum">
                <div className="container">
                    <div className="forum-container new-topic">
                        <h2 className="heading">Novo Tópico</h2>
                        <div className="forum-new-topic-form w-form">
                            <form id="email-form" name="email-form" onSubmit={this.doPost}>
                                <input type="text" className="input w-input" maxLength="256" name="title" data-name="title"
                                    placeholder="Descreva o título da sua questão em poucas palavras" id="title" required />
                                <textarea placeholder="Detalhe e desenvolva o conteúdo da sua questão" maxLength="5000" id="description" name="description"
                                    className="input forum-new-post-textarea w-input" required></textarea>
                                <div className="forum-two-columns">
                                    <select id="course" name="course" className="input select new-topic w-select">
                                        <option value="filhos21">Trilha Filhos 2.1</option>
                                        <option value="other">Outro</option>
                                    </select>
                                    <input type="submit" value="Postar tópico" data-wait="Please wait..." className="btn btn-medium new-topic w-button" disabled={this.state.saving}></input>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

function mapStateToProps(state, { navigation }) {
    return {
        user: state.user.user
    }
}

export default connect(
    mapStateToProps,
    null
)(withRouter(ForumNewCore))