import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import CourseBanner from '../../components/banner/CourseBanner';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import Contents from '../../components/courseDetail/Contents';
import Modal from '../../components/modal/Modal';

class CourseDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contentsLength: 0,
            modalDepoimentOpenned: false
        };
    }

    componentDidMount() {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);

        ReactGA.pageview(window.location.pathname + window.location.search);

        ReactGA.event({
            category: 'Courses',
            action: 'Viewed course',
            label: 'Filhos 2.1',
            nonInteraction: true
        });
    }

    toPath = (e) => {
        e.preventDefault();

        this.props.history.push(e.currentTarget.id);
    }

    setContentsLength = (newContentsLength) => {
        const { contentsLength } = this.state;

        this.setState({
            contentsLength: contentsLength + newContentsLength
        });
    }

    openDepoimentModal = (e) => {
        e.preventDefault();
        this.setState({ modalDepoimentOpenned: true });
    }

    closeDepoimentModal = (e) => {
        e.preventDefault();
        this.setState({ modalDepoimentOpenned: false });
    }

    render() {
        return (
            <div>
                <Header breads={[{ name: 'Trilhas', link: '/dashboard' }, { name: 'Filhos 2.1' }]} />
                <CourseBanner title={"Filhos 2.1: criando filhos no século 21"} />
                <div className="course-detail">
                    <div className="course-detail-bg-left"></div>
                    <div className="container">
                        <div className="course-detail-container">
                            <div className="course-detail-main">
                                <h2 className="course-detail-h2">Conheça a Trilha</h2>
                                <p className="course-detail-p">As mudanças de gerações e as principais características que envolvem cada período. Os desafios do futuro frente às mudanças. Abordagem sobre as forças de caráter e como os pais podem construir condições para que os filhos desenvolvam suas capacidades.</p>
                                <h2 className="course-detail-h2">O que você aprenderá?</h2>
                                <ul className="course-detail-list-learning">
                                    <li className="course-detail-list-learning-item">
                                        <div>O que são habilidades sócio emocionais e quais são as habilidades do futuro.</div>
                                    </li>
                                    <li className="course-detail-list-learning-item">
                                        <div>Quais as forças de caráter e a importância na formação dos filhos.</div>
                                    </li>
                                    <li className="course-detail-list-learning-item">
                                        <div>Como identificar e desenvolver as forças de caráter.</div>
                                    </li>
                                    <li className="course-detail-list-learning-item">
                                        <div>Quais as diferenças entre as gerações? Como entender as diferenças e criar um diálogo com os filhos?</div>
                                    </li>
                                    <li className="course-detail-list-learning-item">
                                        <div>Dicas para lidar com os principais desafios da infância e adolescência.</div>
                                    </li>
                                </ul>
                                <h2 className="course-detail-h2">Conteúdo Detalhado</h2>
                                <div className="course-detail-portlet portlet">
                                    <div className="course-detail-portlet-left">
                                        <div className="course-detail-content-description">
                                            <div className="course-detail-content-icon start"></div>
                                            <div className="course-detail-content-text">
                                                <div className="course-detail-content-title">Antes de Começar</div>
                                                <div className="course-detail-content-mini-desc">Antes de seguir em frente e mergulhar nessa trilha vamos dizer algumas dicas e informações importantes para você aproveitar o máximo de todo conteúdo que está aqui. </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Contents setContentsLength={this.setContentsLength} chapter="sa89s8a9sb" />
                                </div>
                                <div className="course-detail-portlet portlet">
                                    <div className="course-detail-portlet-left">
                                        <div className="course-detail-content-description">
                                            <div className="course-detail-content-icon">
                                                <div>01</div>
                                            </div>
                                            <div className="course-detail-content-text">
                                                <div className="course-detail-content-title">Desafios do Futuro</div>
                                                <div className="course-detail-content-mini-desc">Em um futuro incerto e desafiador e no mundo onde profissões ainda não foram inventadas, como desenvolver além das competências técnicas, entendendo que o comportamento é a chave para o futuro do trabalho. Vamos, lá? </div>
                                            </div>
                                        </div>
                                        <div className="course-detail-content-menu">
                                            <a href="" className="btn-content-menu w-button" id="/chapter/sa89s8a9sa" onClick={this.toPath.bind(this)}><strong>Iniciar</strong> o Curso</a>
                                            <a href="https://miro.com/app/board/o9J_kw2Crq8=" target="_blank" className="btn-content-menu inverted map w-button"><strong>Mapa Mental</strong> da Trilha</a>
                                            <a className="btn-content-menu inverted forum w-button"><strong>Fórum</strong> do Curso</a>
                                        </div>
                                    </div>
                                    <Contents setContentsLength={this.setContentsLength} chapter="sa89s8a9sa" />
                                </div>
                                <div className="course-detail-portlet portlet">
                                    <div className="course-detail-portlet-left">
                                        <div className="course-detail-content-description">
                                            <div className="course-detail-content-icon">
                                                <div>02</div>
                                            </div>
                                            <div className="course-detail-content-text">
                                                <div className="course-detail-content-title">Desafios das Gerações</div>
                                                <div className="course-detail-content-mini-desc">Quais os desafios de se educar as novas gerações? Nesse curso vamos entender as características das gerações  "X", "Y", "Z", "alpha“ e como construir o diálogo para encurtar as distâncias entre pais e filhos. </div>
                                            </div>
                                        </div>
                                        <div className="course-detail-content-menu"><a href="" id="/chapter/sa89s8a9sc" onClick={this.toPath.bind(this)} className="btn-content-menu w-button"><strong>Iniciar</strong> o Curso</a>
                                            <a href="https://miro.com/app/board/o9J_kw2Crq8=" target="_blank" className="btn-content-menu inverted map w-button">
                                                <strong>Mapa Mental</strong> da Trilha</a><a href="#" className="btn-content-menu inverted forum w-button"><strong>Fórum</strong> do Curso</a></div>
                                    </div>
                                    <Contents setContentsLength={this.setContentsLength} chapter="sa89s8a9sc" />
                                </div>
                                <div className="course-detail-portlet portlet">
                                    <div className="course-detail-portlet-left">
                                        <div className="course-detail-content-description">
                                            <div className="course-detail-content-icon">
                                                <div>03</div>
                                            </div>
                                            <div className="course-detail-content-text">
                                                <div className="course-detail-content-title">Forças de Caráter</div>
                                                <div className="course-detail-content-mini-desc">As Forças de Caráter são características positivas e individuais que representam nossa personalidade. Quando colocadas em prática podem trazer um impacto extremamente benéfico. Como desenvolver essas forças e como ajudar os filhos a desenvolvê-las?  </div>
                                            </div>
                                        </div>
                                        <div className="course-detail-content-menu"><a href="" id="/chapter/sa89s8a9sd" onClick={this.toPath.bind(this)} className="btn-content-menu w-button"><strong>Iniciar</strong> o Curso</a>
                                            <a href="https://miro.com/app/board/o9J_kw2Crq8=" target="_blank" className="btn-content-menu inverted map w-button"><strong>Mapa Mental</strong> da Trilha</a><a href="#" className="btn-content-menu inverted forum w-button"><strong>Fórum</strong> do Curso</a></div>
                                    </div>
                                    <Contents setContentsLength={this.setContentsLength} chapter="sa89s8a9sd" />
                                </div>
                                <div className="course-detail-portlet portlet">
                                    <div className="course-detail-portlet-left">
                                        <div className="course-detail-content-description">
                                            <div className="course-detail-content-icon library"></div>
                                            <div className="course-detail-content-text">
                                                <div className="course-detail-content-title">Biblioteca</div>
                                                <div className="course-detail-content-mini-desc">“Qual o nome daquele livro mesmo?” Nem precisa preocupar, aqui você acessa todo conteúdo que nossos especialistas indicaram e pode rever aqueles melhores momentos. </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Contents setContentsLength={this.setContentsLength} chapter="sa89s8a9se" />
                                </div>
                                <h2 className="course-detail-h2">O que dizem nossos alunos</h2>
                                <div className="course-detail-testimonial">
                                    <div className="course-detail-testimonial-left" style={{ cursor: 'pointer' }} onClick={this.openDepoimentModal}></div>
                                    {
                                        this.state.modalDepoimentOpenned &&
                                        <Modal idModal="depoiment" type={"depoiment"} modalOppened={this.state.modalDepoimentOpenned} closeDepoimentModal={this.closeDepoimentModal} />
                                    }
                                    <div className="course-detail-testimonial-right">
                                        <div className="course-detail-testimonial-description">
                                            "O Itinere é uma jornada de construção do indivíduo. E ele cria um locus para que essa reconstrução do pai permita que se construa uma relação pai-filho onde o pano de fundo é a escola"
                                        </div>
                                        <div className="course-detail-testimonial-name">Bruno Preilsticker</div>
                                        <div className="course-detail-testimonial-function">Pai da Gabriela, 11 anos, e da Letícia 14 anos</div>
                                    </div>
                                </div>
                            </div>
                            <div className="course-detail-aside">
                                <div className="course-detail-aside-panels">
                                    <div className="course-detail-aside-panels-left">
                                        <div className="course-detail-aside-info">
                                            <div className="course-detail-aside-info-item">
                                                <div><strong>Duração:</strong> 10hs</div>
                                            </div>
                                            <div className="course-detail-aside-info-item">
                                                <div><strong>Nº de Atividades:</strong> {this.state.contentsLength}</div>
                                            </div>
                                        </div>
                                        <div className="course-detail-aside-title">ASSISTA O PRIMEIRO VÍDEO</div>
                                        <a href="" className="course-detail-aside-first-video w-inline-block" id="/chapter/sa89s8a9sa" onClick={this.toPath.bind(this)}>
                                            <div className="course-detail-aside-first-video-bg">
                                                <div className="course-detail-aside-first-video-play">
                                                    <div>Assistir</div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="courser-detail-aside-panels-right">
                                        <div className="course-detail-aside-title">ESPECIALISTA</div>
                                        <div className="portlet portlet-specialist">
                                            <div className="course-detail-aside-specialist-head">
                                                <div className="specialist-photo"></div>
                                                <div className="specialist-info">
                                                    <div className="specialist-first-name"><strong>Renata</strong></div>
                                                    <div className="specialist-last-name">Livramento</div><a href="https://www.linkedin.com/in/renatalivramento" target="_blank" className="link-block w-inline-block"></a></div>
                                            </div>
                                            <div className="specialist-description">
                                                Psicóloga especialista em psicologia clínica. Doutora, fundadora e presidente do Instituto Brasileiro de Psicologia Positiva (IBRPP). Atua principalmente nas áreas de mentoria e consultoria em Projetos de Psicologia Positiva, Psicoterapia, supervisão Clínica e Coaching Positivo.
                                          </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="course-detail-bg-right"></div>
                </div>
                <Footer />
            </div>
        );
    }
}

function mapStateToProps(state, { navigation }) {
    return {
        chapters: state.chapter.chapters
    }
}

export default connect(
    mapStateToProps,
    null
)(CourseDetail)