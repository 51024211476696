import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    withRouter
} from 'react-router-dom';

class UnauthorizedCore extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    goToRegisterSecoundStep = (e) => {
        e.preventDefault();

        this.props.history.push('/register', { step: 2 });
    }

    render() {
        return (
            <div className="common">
                <div className="container">
                    <div className="common-container">
                        <div className="common-left">
                            <div className="common-title"><strong>Você não tem acesso </strong>a este conteúdo!</div>
                            <div className="common-description"><a href="" onClick={this.goToRegisterSecoundStep}><strong className="common-link">Clique aqui</strong>
                            </a>, assine agora mesmo o Itinere e tenha acesso a este curso e muitos outros.</div>
                        </div>
                        <div className="common-right"></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    null,
    null
)(withRouter(UnauthorizedCore))