import React, { Component } from 'react';
import Inputmask from 'inputmask';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { create } from '../../utils/api';
import { setUser, setUid } from '../../reducers/user';
import { connect } from 'react-redux';
import {
    withRouter
} from 'react-router-dom';
const CPF = require('cpf');

class StepOne extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {},
            saving: false
        };
    }

    componentDidMount() {
        var cpf = document.getElementById("cpf");
        Inputmask({ "mask": "999.999.999.99" }).mask(cpf);
    }

    createUser = (user) => {
        create(user)
            .then((result) => {
                this.props.setUser(result.user);
                this.props.setUid(result.user.uid);
                localStorage.setItem("token", result.token);
                localStorage.setItem("uid", result.user.uid);
                this.props.goToStep(2);
            })
            .catch((error) => {
                if (error && error.code === "auth/invalid-email")
                    ToastsStore.error("Oops... Por favor informe um email válido!");
                else if (error && error.code === "auth/email-already-exists")
                    ToastsStore.error("Oops... Seu email já foi cadastrado!");
                else if (error && error.code === "cpf-already-exists") {
                    ToastsStore.error("Oops... Seu usuário já está cadastrado, efetue login!");
                    setTimeout(() => {
                        this.props.history.push('/');
                    }, 1500);
                }
                else
                    ToastsStore.error("Oops... Ocorreu algum erro!");
            })
            .finally(() => {
                this.setState({ saving: false });
            });
    }

    saveUser = (e) => {
        e.preventDefault();

        if (!CPF.isValid(document.getElementById('cpf').value)) {
            this.setState({ invalidCpf: true });
            return;
        }

        if (document.getElementById('password').value.length < 6) {
            this.setState({ invalidPass: true });
            return;
        }

        let user = {}
        user.name = document.getElementById('name').value;
        user.cpf = CPF.format(document.getElementById('cpf').value);
        user.email = document.getElementById('email').value;
        user.password = document.getElementById('password').value;
        user.type = document.getElementById('type').value;
        this.setState({ saving: true });

        this.createUser(user);
    }

    handleChange = (e) => {
        e.preventDefault();

        this.setState({
            invalidCpf: false
        });
    }

    handleBlurCpf = (e) => {
        e.preventDefault();

        if (!CPF.isValid(document.getElementById('cpf').value))
            this.setState({ invalidCpf: true });
        else
            this.setState({ invalidCpf: false });
    }

    handleBlurPass = (e) => {
        e.preventDefault();

        if (document.getElementById('password').value.length < 6)
            this.setState({ invalidPass: true });
        else
            this.setState({ invalidPass: false });
    }

    handleChangePass = (e) => {
        e.preventDefault();

        this.setState({ invalidPass: false });
    }

    render() {
        let { user, saving } = this.state;
        return (
            <div className="signup-middle-container">
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
                <div className="signup-middle-left"></div>
                <div className="signup-middle-right">
                    <div className="signup-title">
                        <strong>Criar</strong> Conta
                                </div>
                    <div className="signup-form w-form">
                        <form id="step-one-form" name="step-one-form" className="form" onSubmit={this.saveUser}>
                            <input required type="text" className="input w-input" maxLength="256" name="name" placeholder="Seu Nome" id="name" onChange={this.handleChange} />
                            <input required type="text" className="input w-input" maxLength="256" name="cpf" placeholder="Digite seu CPF" id="cpf" onChange={this.handleChange} onBlur={this.handleBlurCpf} />
                            {
                                this.state.invalidCpf &&
                                <div className="error-message">
                                    <div className="text-block"><strong>CPF inválido!</strong> <br />Por favor confira e tente novamente.</div>
                                </div>
                            }
                            <input required type="email" className="input w-input" maxLength="256" name="email" placeholder="nome@email.com" id="email" onChange={this.handleChange} />
                            <input required name="password" id="password" type="password" className="input left w-input" maxLength="256" placeholder="Senha" onChange={this.handleChangePass} onBlur={this.handleBlurPass} />
                            {
                                this.state.invalidPass &&
                                <div className="error-message">
                                    <div className="text-block"><strong>Senha inválida!</strong> <br />Por favor digite uma senha de pelo menos 6 caracteres.</div>
                                </div>
                            }
                            <select id="type" name="type" data-name="type" className="input select profile w-select" onChange={this.handleChange}>
                                <option selected={(user.type === "father") ? "selected" : ""} value="father">Eu sou Pai/Mãe</option>
                                <option selected={(user.type === "educator") ? "selected" : ""} value="educator">Eu sou Educador</option>
                                <option selected={(user.type === "responsible") ? "selected" : ""} value="responsible">Eu sou Responsável</option>
                                <option selected={(user.type === "other") ? "selected" : ""} value="other">Eu sou Outro</option>
                            </select>
                            <input type="submit" value={saving ? "" : "Criar minha conta"} className={saving ? "btn btn-login w-button btn-loading" : "btn btn-login w-button"} disabled={saving} />
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, { navigation }) {
    return {
    }
}

const mapDispatchToProps = dispatch => ({
    setUser: user => dispatch(setUser(user)),
    setUid: uid => dispatch(setUid(uid))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(StepOne))