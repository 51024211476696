// Title.jsx
import React from 'react'

const loadHtml = (source, id) => {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', source, true);

    xhr.onreadystatechange = function () {
        if (this.readyState !== 4) return;
        if (this.status !== 200) return; // or whatever error handling you want
        let htmlContent = this.responseText;
        htmlContent = htmlContent.replace('../images/', 'https://itinere-plataforma.s3.us-east-2.amazonaws.com/');
        document.getElementById(id).innerHTML = htmlContent;
    };

    xhr.send();
}

const Title = props => {
    loadHtml(props.contentSelected.source, 'div' + props.contentSelected.code);

    return (
        <div id={'div' + props.contentSelected.code}></div>
    )
}

export default Title