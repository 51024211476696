import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    withRouter
} from 'react-router-dom';
import FixedPost from './FixedPost';
import NormalPost from './NormalPost';
import { getPosts } from '../../utils/api';

class ForumCore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            loading: true,
            pageNumber: 1
        }
    }

    componentDidMount() {
        this.getAllPosts();
    }

    getAllPosts = () => {
        this.goToPage(this.state.pageNumber);
    }

    getTotalOfPages = (totalOfPosts) => {
        let pages = [];
        let page = 0;

        for (var i = 0; i < totalOfPosts; i += 5) {
            page++;
            pages.push(page);
        }

        return pages;
    }

    goToPage = (page) => {
        this.setState({ loadingPagination: true, pageNumber: parseInt(page) });
        getPosts(page)
            .then((result) => {
                this.setState({
                    posts: result.posts,
                    totalOfPages: this.getTotalOfPages(result.posts.totalOfPosts),
                    loadingPagination: false,
                    loading: false
                });
            });
    }

    toPage = (e) => {
        e.preventDefault();

        if (e.currentTarget.id[0] === '0')
            this.goToPage(parseInt(e.currentTarget.id.substr(1)) - 1);
        else if (e.currentTarget.id[0] === '1')
            this.goToPage(parseInt(e.currentTarget.id.substr(1)) + 1);
        else
            this.goToPage(parseInt(e.currentTarget.id.substr(1)));
    }

    toPath = (e) => {
        e.preventDefault();

        this.props.history.push(e.currentTarget.id);
    }

    render() {
        return (
            <main className="forum">
                {
                    !this.state.loading &&
                    <div className="container">
                        <div className="forum-container">
                            <div className="forum-head">
                                <h2 className="heading">Fórum de Discussão</h2>
                                <a href="/forum-new" id="/forum-new" className="btn btn-medium w-button" onClick={this.toPath.bind(this)}><strong>Novo</strong> Tópico</a>
                            </div>
                            <div className="forum-posts">
                                {
                                    this.state.posts && this.state.posts.fixedsPosts &&
                                    this.state.posts.fixedsPosts.map((post) => {
                                        return <FixedPost key={post.code} post={post} />
                                    })
                                }
                                {
                                    this.state.posts && this.state.posts.normalPosts && !this.state.loadingPagination &&
                                    this.state.posts.normalPosts.map((post) => {
                                        return <NormalPost key={post.code} post={post} />
                                    })
                                }
                                {
                                    this.state.loadingPagination &&
                                    <div style={{ display: "flex", alignItems: "center", width: "100%", height: "500px", justifyContent: "center" }}>
                                        <img src={require('../../assets/images/rolling.svg')} alt="" width="30px" height="30px" />
                                    </div>
                                }
                            </div>
                        </div>
                        {
                            this.state.totalOfPages.length > 0 &&
                            <div className="forum-pagination">
                                <a href="" className="pagination-arrow w-inline-block" id={'0' + this.state.pageNumber}
                                    onClick={!(this.state.pageNumber === 1) ? this.toPage.bind(this) : (e) => { e.preventDefault() }}
                                    style={this.state.pageNumber === 1 ? { cursor: 'not-allowed' } : { cursor: '' }}></a>
                                {
                                    this.state.totalOfPages &&
                                    this.state.totalOfPages.map((page) => {
                                        return <a href="" className={page === this.state.pageNumber ? "forum-pagination-item current" : "forum-pagination-item"}
                                            onClick={!(page === this.state.pageNumber) ? this.toPage.bind(this) : (e) => { e.preventDefault() }} id={'-' + page} key={'-' + page}
                                            style={page === this.state.pageNumber ? { cursor: 'not-allowed' } : { cursor: '' }}>
                                            {page}
                                        </a>
                                    })
                                }
                                <a href="" className="pagination-arrow right w-inline-block" id={'1' + this.state.pageNumber}
                                    onClick={!(this.state.pageNumber === this.state.totalOfPages.length) ? this.toPage.bind(this) : (e) => { e.preventDefault() }}
                                    style={this.state.pageNumber === this.state.totalOfPages.length ? { cursor: 'not-allowed' } : { cursor: '' }}></a>
                            </div>
                        }
                    </div>
                }
                {
                    this.state.loading &&
                    <div style={{ display: "flex", alignItems: "center", width: "100%", height: "500px", justifyContent: "center" }}>
                        <img src={require('../../assets/images/rolling.svg')} alt="" width="40px" height="40px" />
                    </div>
                }
            </main>
        );
    }
}

export default connect(
    null,
    null
)(withRouter(ForumCore))