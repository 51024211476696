import React from 'react';
import ReactGA from 'react-ga';
import ReactDOM from 'react-dom';
import './assets/css/normalize.css';
import './assets/css/webflow.css';
import './assets/css/global.css';
import './assets/css/itinere.webflow.css';
import './assets/css/filhos-2-1-conteudo.webflow.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';

ReactGA.initialize('UA-149342472-1');
ReactGA.pageview(window.location.pathname + window.location.search);

if (sessionStorage.getItem('app-data')) {
  ReactGA.set({ 
    userId: JSON.parse(sessionStorage.getItem('app-data')).user.name + ' - ' + JSON.parse(sessionStorage.getItem('app-data')).user.id
  });
}

ReactDOM.render(
    <Router>
        <App />
    </Router>,
    document.getElementById('root'));

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
