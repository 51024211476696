const api = 'https://us-central1-itinere-1bcb0.cloudfunctions.net/api/v1/public';
const apiFiles = 'https://upload-files-s3.herokuapp.com/v1/public';
//const api = 'http://localhost:8000/v1/public';
//const apiFiles = 'http://localhost:8000/v1/public';

let headers = {
    Accept: "application/json",
    Authorization: Math.random()
        .toString(36)
        .substr(-8)
};

// Content Region
export const getContentsByChapter = (chapter) => {
    headers.token = localStorage.getItem("token");
    headers.uid = localStorage.getItem("uid");

    return fetch(`${api}/contents?chapter=${chapter}`, { method: "GET", headers })
        .then(handleRequest)
        .catch(result => handleError(result, `${api}/contents?chapter=${chapter}`, { method: "GET", headers }));
}

// User Region
export const login = (email, password) =>
    fetch(`${api}/users/login?email=${email}&password=${password}`, { method: "GET", headers })
        .then(handleRequest)
        .catch(result => handleError(result, `${api}/users/login?email=${email}&password=${password}`, { method: "GET", headers }));

export const logout = () => new Promise((resolve, reject) => {
    localStorage.removeItem("token");
    localStorage.removeItem("uid");

    return resolve(true);
});

export const resetPassword = (email) => {
    headers.token = localStorage.getItem("token");
    headers.uid = localStorage.getItem("uid");

    return fetch(`${api}/users/resetPassword?email=${email}`, { method: "POST", headers })
        .then(handleRequest)
        .catch(result => handleError(result, `${api}/users/resetPassword?email=${email}`, { method: "POST", headers }));
}

export const setFinishedContent = (contentCode) => {
    headers.token = localStorage.getItem("token");
    headers.uid = localStorage.getItem("uid");

    return fetch(`${api}/users/${headers.uid}/finishContent?contentCode=${contentCode}`, { method: "PUT", headers })
        .then(handleRequest)
        .catch(result => handleError(result, `${api}/users/${headers.uid}/finishContent?contentCode=${contentCode}`, { method: "POST", headers }));
}

export const create = (user) => {
    return fetch(`${api}/users`, {
        method: "POST",
        headers: {
            ...headers,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ user })
    })
        .then(handleRequest)
        .catch(result => handleError(result, `${api}/users`, {
            method: "POST",
            headers: {
                ...headers,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ user })
        }));
}

export const edit = (user) => {
    headers.token = localStorage.getItem("token");
    headers.uid = localStorage.getItem("uid");

    return fetch(`${api}/users/`, {
        method: "PUT",
        headers: {
            ...headers,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ user })
    })
        .then(handleRequest)
        .catch(result => handleError(result, `${api}/users/`, {
            method: "PUT",
            headers: {
                ...headers,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ user })
        }));
}

export const createPaymentToken = (uid, token) => {
    headers.token = localStorage.getItem("token");
    headers.uid = uid;

    return fetch(`${api}/users/${uid}/token`, {
        method: "POST",
        headers: {
            ...headers,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ token })
    })
        .then(handleRequest)
        .catch(result => handleError(result, `${api}/users/${uid}/token`, {
            method: "POST",
            headers: {
                ...headers,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ token })
        }));
}

export const createSign = (uid, couponCode) => {
    headers.uid = uid;

    return fetch(`${api}/users/${uid}/sign`, {
        method: "POST",
        headers: {
            ...headers,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ couponCode })
    })
        .then(handleRequest)
        .catch(result => handleError(result, `${api}/users/${uid}/sign`, {
            method: "POST",
            headers: {
                ...headers,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ couponCode })
        }));
}

export const cancelSign = (uid) => {
    headers.uid = uid;

    return fetch(`${api}/users/${uid}/sign`, {
        method: "DELETE",
        headers: {
            ...headers,
            "Content-Type": "application/json"
        }
    })
        .then(handleRequest)
        .catch(result => handleError(result, `${api}/users/${uid}/sign`, {
            method: "DELETE",
            headers: {
                ...headers,
                "Content-Type": "application/json"
            }
        }));
}

export const reactivateSign = (uid) => {
    headers.uid = uid;

    return fetch(`${api}/users/${uid}/sign`, {
        method: "PATCH",
        headers: {
            ...headers,
            "Content-Type": "application/json"
        }
    })
        .then(handleRequest)
        .catch(result => handleError(result, `${api}/users/${uid}/sign`, {
            method: "DELETE",
            headers: {
                ...headers,
                "Content-Type": "application/json"
            }
        }));
}

export const updatePassword = (oldPassword, newPassword, email) => {
    headers.token = localStorage.getItem("token");
    headers.uid = localStorage.getItem("uid");

    return fetch(`${api}/users/${headers.uid}/updatePassword?oldPassword=${oldPassword}&newPassword=${newPassword}&email=${email}`, { method: "PUT", headers })
        .then(handleRequest)
        .catch(result => handleError(result, `${api}/users/${headers.uid}/updatePassword?oldPassword=${oldPassword}&newPassword=${newPassword}&email=${email}`, { method: "PUT", headers }));
}

export const checkUserStatus = () => {
    headers.token = localStorage.getItem("token");
    headers.uid = localStorage.getItem("uid");

    return fetch(`${api}/users/checkUserStatus`, { method: "GET", headers })
        .then(handleRequest)
        .catch(result => handleError(result, `${api}/users/checkUserStatus`, { method: "GET", headers }));
}

// Files Region
export const uploadPhoto = (file) => {
    headers.token = localStorage.getItem("token");
    headers.uid = localStorage.getItem("uid");

    return fetch(`${apiFiles}/itinere`, {
        method: "POST",
        body: file,
        headers: {
            ...headers
        },
    })
        .then(handleRequest)
        .catch(result => handleError(result, `${apiFiles}/itinere`, {
            method: "POST",
            body: file,
            headers: {
                ...headers
            },
        }));
}

// Coupon Region
export const getCouponValidation = (couponCode) => {
    return fetch(`${api}/coupons/${couponCode}`, { method: "GET", headers })
        .then(handleRequest)
        .catch(result => handleError(result, `${api}/coupons/${couponCode}`, { method: "GET", headers }));
}

// Post Region
export const getPosts = (pageNumber) => {
    headers.token = localStorage.getItem("token");
    headers.uid = localStorage.getItem("uid");

    return fetch(`${api}/posts?pageNumber=${pageNumber}`, { method: "GET", headers })
        .then(handleRequest)
        .catch(result => handleError(result, `${api}/posts?pageNumber=${pageNumber}`, { method: "GET", headers }));
}

export const getPostByCode = (postCode) => {
    headers.token = localStorage.getItem("token");
    headers.uid = localStorage.getItem("uid");

    return fetch(`${api}/posts/${postCode}`, { method: "GET", headers })
        .then(handleRequest)
        .catch(result => handleError(result, `${api}/posts/${postCode}`, { method: "GET", headers }));
}

export const sendPost = (post) => {
    headers.token = localStorage.getItem("token");
    headers.uid = localStorage.getItem("uid");

    return fetch(`${api}/posts`, {
        method: "POST",
        headers: {
            ...headers,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ post })
    })
        .then(handleRequest)
        .catch(result => handleError(result, `${api}/posts`, {
            method: "POST",
            headers: {
                ...headers,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ post })
        }));
}

export const deletePost = (code) => {
    headers.token = localStorage.getItem("token");
    headers.uid = localStorage.getItem("uid");

    return fetch(`${api}/posts/${code}`, {
        method: "DELETE",
        headers: {
            ...headers,
            "Content-Type": "application/json"
        }
    })
        .then(handleRequest)
        .catch(result => handleError(result, `${api}/posts/${code}`, {
            method: "DELETE",
            headers: {
                ...headers,
                "Content-Type": "application/json"
            }
        }));
}

// Region Replies
export const sendReply = (reply) => {
    headers.token = localStorage.getItem("token");
    headers.uid = localStorage.getItem("uid");

    return fetch(`${api}/replies`, {
        method: "POST",
        headers: {
            ...headers,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ reply })
    })
        .then(handleRequest)
        .catch(result => handleError(result, `${api}/replies`, {
            method: "POST",
            headers: {
                ...headers,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ reply })
        }));
}

export const deleteReply = (code) => {
    headers.token = localStorage.getItem("token");
    headers.uid = localStorage.getItem("uid");

    return fetch(`${api}/replies/${code}`, {
        method: "DELETE",
        headers: {
            ...headers,
            "Content-Type": "application/json"
        }
    })
        .then(handleRequest)
        .catch(result => handleError(result, `${api}/replies/${code}`, {
            method: "DELETE",
            headers: {
                ...headers,
                "Content-Type": "application/json"
            }
        }));
}

// General functions
const handleRequest = async (response) => {
    const status = response.status;
    const result = await response.json();

    if (result.data && result.data.userLogged)
        localStorage.setItem("token", result.data.userLogged.user.stsTokenManager.accessToken);

    if (response.ok)
        return result;
    else
        throw { result, status };
}

const handleError = (data, endpoint, params) => {
    const { result, status } = data;

    if (status === 401 && result && result.newToken === undefined) {
        localStorage.removeItem("token");
        localStorage.removeItem("state");
        localStorage.removeItem("uid");
        window.location.pathname = "/"
    }

    if (endpoint && params && result && result.newToken) {
        localStorage.setItem("token", result.newToken);
        headers.token = result.newToken;

        let newParams = params;
        newParams.headers = headers;

        return fetch(endpoint, params)
            .then(handleRequest)
            .catch(result => handleError(result, undefined, undefined));
    }

    if (result && result.err === undefined) {
        throw result;
    } else if (result)
        throw result.err;
    else
        throw result;
}