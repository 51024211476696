import React, { Component } from 'react';
import ReactGA from 'react-ga';
import LoginForm from '../../components/login/LoginForm';
import ResetPasswordForm from '../../components/login/ResetPasswordForm';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { login, resetPassword } from '../../utils/api';
import { setLoginError, setUser, setUid } from '../../reducers/user';
import { connect } from 'react-redux';
import {
  withRouter
} from 'react-router-dom';

import './Login.css';

class Login extends Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      invalidLogin: false,
      location: window.location.pathname
    };
  }

  componentDidMount() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  }

  signIn = (e) => {
    e.preventDefault();
    localStorage.removeItem("token")

    this.setState({ loading: true });

    const email = document.getElementById("email").value;
    const senha = document.getElementById("senha").value;
    const reactGa = ReactGA;

    login(email, senha)
      .then((result) => {
        reactGa.event({
          category: 'User',
          action: 'Login',
          label: `${result.data.userDoc.displayName} (${result.data.userDoc.cpf})`
        });

        this.props.setUser(result.data.userDoc);
        this.props.setUid(result.data.userLogged.user.uid);
        this.props.history.push('/dashboard');
      })
      .catch((error) => {
        this.props.setLoginError(true);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  resetPassword = (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    const email = document.getElementById("email").value;
    const reactGa = ReactGA;

    resetPassword(email)
      .then(() => {
        reactGa.event({
          category: 'User',
          action: 'Reset Password',
          label: email
        });
        this.props.setLoginError(false);
        ToastsStore.success("Email enviado com sucesso!");
        setTimeout(() => {
          this.props.history.push('/');
        }, 2000);
      })
      .catch((error) => {
        this.props.setLoginError(true);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  toPath = (e) => {
    e.preventDefault();

    this.props.history.push(e.currentTarget.id);
  }

  render() {
    const forgotPassword = window.location.pathname === "/esqueci-minha-senha";

    return (
      <main className="login">
        <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
        <div className="login-left">
          <div className="login-left-header">
            <a href="http://www.itinere.com.br" className="w-inline-block">
              <img src={require('../../assets/images/itinere-logo.png')} alt="" /></a>
          </div>
          <div className="login-left-middle">
            <div className="login-person"></div>
            <div className="login-content">
              <h2 className="login-content-title">Aprendendo e ensinando com a nova geração</h2>
              <div className="login-content-text">Aqui, você encontra os melhores caminhos para participar do desenvolvimento de uma geração que vive cada vez mais conectada</div>
            </div>
          </div>
          <div className="login-left-footer">
            <div className="gray-16">© 2019 Itinere</div>
          </div>
        </div>
        <div className="login-right">
          {
            forgotPassword ?
              <div className="login-right-header left">
                <img src={require('../../assets/images/arrow-left.svg')} alt="" className="arrow-left" id="/" onClick={this.toPath} style={{ cursor: 'pointer' }} />
                <a href="/" className="login-no-account-link" id="/" onClick={this.toPath}>Voltar para o login<strong></strong></a>
              </div>
              :
              <div className="login-right-header">
                <a href="/register" id="/register" onClick={this.toPath} className="login-no-account-link">Não tem uma conta? <strong>Assine agora!</strong></a>
              </div>
          }
          {
            forgotPassword ?
              <ResetPasswordForm resetPassword={this.resetPassword} loading={this.state.loading} /> :
              <LoginForm signIn={this.signIn} loading={this.state.loading} />
          }
        </div>
      </main>
    );
  }
}

function mapStateToProps(state, { navigation }) {
  return {}
}

const mapDispatchToProps = dispatch => ({
  setLoginError: data => dispatch(setLoginError(data)),
  setUser: user => dispatch(setUser(user)),
  setUid: uid => dispatch(setUid(uid))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Login))