import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    withRouter
} from 'react-router-dom';

class NotFoundCore extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    goToHome = (e) => {
        e.preventDefault();

        this.props.history.push('/dashboard');
    }

    goToLogin = (e) => {
        e.preventDefault();

        this.props.history.push('/');
    }

    render() {
        return (
            <div className="common">
                <div className="container">
                    <div className="common-container">
                        <div className="common-left">
                            <div className="common-title"><strong>Ops! Página não encontrada!</strong></div>
                            <div className="common-description">Infelizmente não conseguimos encontrar o que você estava procurando.
                                            <a href="" onClick={this.props.user && this.props.user.name ? this.goToHome : this.goToLogin}>
                                    <strong className="common-link">Voltar para o início</strong>
                                </a><strong>.</strong>
                            </div>
                        </div>
                        <div className="common-right _404"></div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, { navigation }) {
    return {
        user: state.user.user
    }
}

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(NotFoundCore))