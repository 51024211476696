import React, { Component } from 'react';
import ReactGA from 'react-ga';
import StepOne from '../../components/register/StepOne';
import StepTwo from '../../components/register/StepTwo';
import './Register.css';

export default class Register extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {},
            step: props.location && props.location.state && props.location.state.step ? props.location.state.step : 1
        };
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 200);
    }

    goToStep = (step) => {
        this.setState({ step });
    }

    render() {
        const { step } = this.state;

        return (
            <main className="signup">
                <div className="signup-container">
                    <div className="signup-header">
                        <a href="http://www.itinere.com.br" className="w-inline-block">
                            <img src={require('../../assets/images/itinere-logo.png')} alt="" />
                        </a>
                    </div>
                    <div className="signup-middle">
                        {
                            step === 1 &&
                            <StepOne goToStep={this.goToStep} />
                        }
                        {
                            step === 2 &&
                            <StepTwo />
                        }
                        <div className="signup-pagination">
                            <div className={step === 1 ? "signup-pagination-item current" : "signup-pagination-item"}></div>
                            <div className={step === 2 ? "signup-pagination-item current" : "signup-pagination-item"}></div>
                            <div className="signup-pagination-item"></div>
                        </div>
                    </div>
                    <div className="signup-footer">
                        <div className="gray-16">© 2019 Itinere</div>
                    </div>
                </div>
            </main>
        );
    }
}