import React, { Component } from 'react';

export default class AdhesionTerm extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="container">
                <div className="portlet-modal" style={{ minWidth: '50%' }}>
                    <div className="portlet-modal-header" style={{ minWidth: '50%' }}>
                        <div className="portlet-modal-title">Termo e condições de uso</div>
                        <div className="portlet-modal-close" style={{ cursor: "pointer" }} onClick={this.props.closeModal}></div>
                    </div>
                    <div className="portlet-modal-main" style={{ minWidth: '50%' }}>
                        <div className="signup-middle-container">
                            Versão: 2019/09/12
    
    TERMOS E CONDIÇÕES DE USO
    
    POR FAVOR, LEIA ESTE DOCUMENTO COM ATENÇÃO, POIS O USO DA PLATAFORMA ITINERE POR VOCÊ DEMONSTRA A SUA EXPRESSA CONCORDÂNCIA COM ESTES TERMOS.
    
    O presente aplicativo (“Plataforma ITINERE” ou simplesmente “ITINERE”) é uma plataforma do VICARIATO AGOSTINIANO DE NOSSA SENHORA DA CONSOLAÇÃO DO BRASIL (doravante denominada simplesmente VICARIATO ou ITINERE), de cursos e de conteúdos voltados à educação através da qual você (Usuário – doravante simplesmente “você”), através do seu Login – via Usuário e senha – pode ter acesso, para o fim de possibilitar o entendimento sobre a mudança de gerações e os desafios do futuro.
    
    Estes TERMOS E CONDIÇÕES DE USO ("Termos de Uso"), juntamente com a Política de Privacidade, descrevem os termos e as condições aplicáveis ao acesso e uso das funcionalidades da Plataforma ITINERE por você. Tanto os Termos de Uso quanto a Política de Privacidade são aplicáveis ao aplicativo ITINERE e demais produtos vinculados à Plataforma.
    
    POR FAVOR, LEIA ATENTAMENTE OS TERMOS E CONDIÇÕES AQUI APRESENTADOS E TODOS OS OUTROS AVISOS QUE POSSAM APARECER EM OUTRAS PÁGINAS DA PLATAFORMA ITINERE.
    
    AO CLICAR EM “EU LI e ACEITO” OU AO NAVEGAR NA PLATAFORMA ITINERE, VOCÊ CONCORDA COM ESTES TERMOS DE USO. SE VOCÊ NÃO CONCORDAR COM TODOS OS TERMOS QUE SE SEGUEM, NÃO PODERÁ UTILIZAR OU ACESSAR O ITINERE A QUALQUER TÍTULO.
    
    Toda vez que houver menção aos termos “ITINERE”, “Plataforma”, “nós” ou “nossos” estaremos nos referindo ao ITINERE; bem como toda vez que houver menção aos termos “você”, “Usuário”, “seu” ou “sua”,  estaremos nos referindo a você Usuário, que está consentindo, com estes Termos de Uso e com a Política de Privacidade para fazer uso e ter acesso à Plataforma.
    
    NÓS PODEREMOS MODIFICAR ESTES TERMOS DE USO A QUALQUER MOMENTO, A NOSSO EXCLUSIVO CRITÉRIO, MEDIANTE A PUBLICAÇÃO DE TERMOS DE USO ATUALIZADOS. Para a sua comodidade, a data da última revisão é incluída no topo deste documento. Recomendamos que toda vez que você acessar a Plataforma do ITINERE, fique atento às novas atualizações, pois o seu acesso e uso da Plataforma estarão vinculados por quaisquer alterações destes Termos de Uso.
    
    CLÁUSULA 1ª - 	Plataforma ITINERE
    1.1-	As atividades desenvolvidas através do ITINERE consistem, dentre outras, na disponibilização de conteúdos educacionais e comportamentais, através da qual você,  através do seu Login – via Usuário e senha – pode ter acesso, para o fim de possibilitar o entendimento sobre a mudança de gerações e os desafios do futuro.
    1.2-	Para usar os serviços da Plataforma ITINERE, se for menor de idade, você precisa estar devidamente assistido e representado nos termos da legislação aplicável.
    
    CLÁUSULA 2ª - 	Acesso e uso da Plataforma
    2.1-	Cadastro do Usuário: Para que o Usuário possa desfrutar dos serviços disponibilizados na Plataforma do ITINERE, faz-se necessário o seu prévio cadastro, contendo informações para acesso, tais como nome de Usuário (login) e senha, bem como outros dados pessoais solicitados. O cadastro é realizado por meio do preenchimento do formulário on-line disponível na página inicial da Plataforma do ITINERE.
    2.2-	Além do preenchimento do formulário on-line, o ITINERE poderá solicitar ao Usuário o envio de documentos comprobatórios tais como RG e CPF para validação das informações fornecidas pelos Usuários.
    2.3-	Você é responsável por manter a confidencialidade de seus dados de login e senha, criados no ato de registro na Plataforma do ITINERE, bem como pelo acesso ao conteúdo disponibilizado na referida plataforma. Você não poderá transmitir nem compartilhar com terceiros o seu nome de Usuário e senha, nem os conteúdos disponibilizados a você pela Plataforma do ITINERE. Você é única e exclusivamente responsável por todas as atividades que ocorram sob seu Usuário, por isso, tenha cuidado ao usar redes e computadores públicos e/ou de terceiros, cuja segurança não seja razoável e/ou gravar senhas de forma automática em computadores. Você concorda em notificar o ITINERE imediatamente se suspeitar de qualquer utilização não autorizada de seu login e senha. O ITINERE não será responsável por quaisquer perdas e danos resultantes de acessos não autorizados ou uso de sua conta em caso de descuido ou negligência do Usuário na confidencialidade do login e senha.
    2.4-	Você concorda, ainda, em não utilizar o login e senha de outro Usuário, a qualquer momento, bem como a não divulgar, disponibilizar ou compartilhar os conteúdos acessados através da Plataforma do ITINERE com quaisquer terceiros.
    2.5-	Ao usar a Plataforma do ITINERE e suas funcionalidades você garante que: (a) todas as informações cadastrais inseridas na Plataforma do ITINERE são verdadeiras, exatas, atuais e completas; (b) ao usar esta Plataforma você não viola qualquer lei ou regulamento aplicável; (c) você se compromete e garante que não fará uso nem permitirá que terceiros façam uso da Plataforma, bem como dos conteúdos que lhe são disponibilizados, para outra finalidade além daquela prevista no presente Termo; (d) você não realizará e não permitirá que nenhum terceiro realize engenharia reversa da Plataforma; e (f) respeitará os Direitos de Propriedade Intelectual (conforme definido abaixo), entre outros.
    2.6-	O "nome de Usuário" (login) não poderá guardar semelhança com o nome "Plataforma do ITINERE" ou “ITINERE”. Também poderão ser cancelados Cadastros efetuados com "nomes de Usuário" (login) considerados ofensivos.
    2.7-	O Usuário deverá solicitar imediatamente a alteração de senha caso o Usuário tenha conhecimento ou suspeite de que terceiros tiveram acesso a seu login e/ou senha, ou da ocorrência de uso ou acesso não autorizado de sua conta na Plataforma do ITINERE por terceiros.
    2.8-	Se qualquer informação fornecida pelo Usuário for falsa, incorreta, desatualizada ou incompleta, ou caso sejam identificadas razões suficientes para suspeitar que tal informação seja falsa, incorreta, desatualizada ou incompleta, teremos o direito de suspender ou cancelar imediatamente, independente de notificação prévia, a conta de cadastro do Usuário e recusar toda e qualquer utilização, presente ou futura do serviço, ou parte dele.
    2.9-	O ITINERE poderá, a seu exclusivo critério, requisitar documentos e informações adicionais para confirmar ou manter o cadastro de qualquer Usuário. Caso o ITINERE decida exercer essa faculdade em relação a qualquer Usuário, o cadastro desse Usuário poderá ser suspenso ou definitivamente cancelado caso o Usuário se recuse a prestar as informações ou a enviar os documentos requeridos ou, caso, ainda, se constate, a partir da análise de tais informações e documentos, que o Usuário inseriu informação falsa ou incompleta no momento de seu cadastro.
    2.10-	Cada Usuário poderá manter apenas um único cadastro na Plataforma do ITINERE, o qual deve ter informações cadastrais compatíveis e coerentes com as informações prestadas à instituição de ensino na qual está matriculado. Caso seja constatada a existência de mais de um cadastro relativo a uma mesma pessoa, um ou todos esses cadastros poderão ser suspensos ou cancelados, a critério da ITINERE.
    2.11-	O ITINERE se reserva o direito de recusar qualquer solicitação de cadastro e de suspender ou cancelar cadastro(s) previamente aceito(s) nos casos (i) de violação de qualquer das disposições destes Termos de Uso; (ii) impossibilidade de verificação da identidade do Usuário ou constatação de falsidade em qualquer das informações por ele fornecidas; e (iii) prática pelo Usuário de atos fraudulentos ou dolosos ou a adoção de qualquer comportamento que, a critério do ITINERE, seja incompatível com os objetivos da Plataforma do ITINERE ou que possam, de qualquer modo, causar danos a terceiros ou à Plataforma.
    2.12-	Caso você tenha qualquer motivo para acreditar ou suspeitar que qualquer informação que temos a seu respeito está errada ou incompleta, ou ainda, caso queira  revogar o consentimento para tratamento e arquivamento das suas informações pessoais, pedimos que, por favor, entre em contato com nossa central de atendimento ou escreva um e-mail o quanto antes para nossa equipe no endereço: contato@itinere.com.br. Em caso de revogação de consentimento, o acesso à Plataforma do ITINERE será automaticamente encerrado e o presente contrato estará automaticamente rescindido.
    2.13-	O acesso à Plataforma do ITINERE para visitantes que não possuem cadastro (aqueles que apenas visitam e navegam no aplicativo, sem realizar qualquer tipo de cadastro ou login), se restringe à possibilidade de visualização das funcionalidades da Plataforma, sendo vedado o acesso aos conteúdos disponibilizados na Plataforma a cada Usuário de forma personalizada.
    CLÁUSULA 3ª - 	Responsabilidades do Usuário. Para acessar os conteúdos disponibilizados na Plataforma, o Usuário deve, previamente, ter efetuado seu cadastro com sucesso e ter aceitado todas as disposições dos presentes Termos de Uso e da Política de Privacidade, e ainda:
    3.1-	providenciar o seu próprio acesso à Internet e pagar todas as taxas de serviço eventualmente cobradas com relação a tal acesso;
    3.2-	providenciar todo o equipamento e pagar todas as taxas necessárias para efetuar sua conexão à Internet, incluindo, mas não se limitando, a um computador, tablet ou celular e um provedor;
    3.3-	efetuar seu próprio cadastro na Plataforma, responsabilizando-se pela correção e veracidade dos dados informados, assim como pela guarda de sua senha de acesso, conforme acima detalhado; e
    3.4-	manter o ambiente de acesso à Plataforma do ITINERE seguro, com uso de ferramentas disponíveis como antivírus e ﬁrewall, entre outras, atualizadas, de modo a contribuir na prevenção de riscos eletrônicos.
    CLÁUSULA 4ª - 	Acesso aos conteúdos da Plataforma:
    4.1-	Os conteúdos, em formato de vídeos ou qualquer outro formato, serão disponibilizados periodicamente, conforme especificado na Plataforma.
    4.2-	Cada Usuário somente terá acesso aos conteúdos disponibilizados na Plataforma ITINERE em relação aos quais tenha adquirido e pago o preço fixado na Plataforma.
    4.3-	Os conteúdos poderão ser acessados de qualquer dispositivo, a qualquer momento, enquanto você Usuário estiver sendo efetuando o pagamento do preço estipulado na Plataforma.
    4.3.1-	O ITINERE poderá, a qualquer tempo e a seu exclusivo critério, indisponibilizar e apagar conteúdos, desde que disponibilize outro(s) conteúdo(s) em substituição, com mesma qualidade ou superior, garantindo que não haja prejuízo para o Usuário.
    4.4-	Os conteúdos se tornarão automaticamente indisponíveis caso o Usuário não pague o preço correspondente para acesso à Plataforma do ITINERE ou, de qualquer forma, utilize a Plataforma do ITINERE em desconformidade com as disposições do presente Termo.
    4.5-	Possibilidades de interação. Além de assistir aos vídeos, o Usuário que desejar poderá participar de fóruns de discussão sobre os conteúdos disponibilizados através de acesso na própria Plataforma do ITINERE.
    4.5.1-	Não serão tolerados comentários com conteúdo impróprio, preconceituoso, ofensivo, difamatório, profano, ou que, de qualquer forma, seja entendido pelo ITINERE como prejudicial ou inadequado.
    4.5.2-	Não é permitida a disseminação de notícias falsas; a divulgação de informações confidenciais, pessoais ou sensíveis próprios ou de terceiros; a utilização de linguagem chula, de baixo calão, depreciativa ou que contenha tom inflamatório, com insultos ou incitação ao ódio e à violência, bem com o uso do fórum de discussão para realização de propaganda ou discussão eleitoral ou campanha político partidária.
    4.5.3-	O ITINERE se reserva o direito de bloquear a participação do Usuário em fórum de discussão ou debate, bem como excluir os seus comentários, caso sejam violadas as disposições destes Termos de Uso acima, especialmente as previstas nesta Cláusula 4.5 ou caso o ITINERE entenda ser prejudicial ou inadequado qualquer comentário do Usuário.
    4.5.4-	As possibilidades de interação não configuram ferramenta de “tira-dúvidas”; portanto, não serão respondidas dúvidas e questões endereçadas aos profissionais responsáveis pelos conteúdos disponibilizados.
    4.5.5-	Caso o Usuário deseje cancelar alguma interação (curtida ou comentário) por ele publicada, o Usuário poderá fazê-lo imediatamente e a qualquer tempo, na própria Plataforma do ITINERE.
    4.6-	O ITINERE não é responsável pelos comentários e conteúdos disponibilizados pelos próprios Usuários na Plataforma do ITINERE. Caso você verifique algum conteúdo impróprio ou inadequado divulgado por outro Usuário, pedimos a gentileza que entre em contato conosco através do e-mail contato@ITINERE.com.br para que o ITINERE possa apurar os fatos e tomar as medidas que entender cabíveis.
    4.7-	O ITINERE recomenda que todo acesso à Plataforma seja realizado com cautela, bom senso e adequado nível de compreensão pelo Usuário. Havendo qualquer dúvida quanto às funcionalidades da Plataforma, o Usuário deverá contatar o ITINERE.
    CLÁUSULA 5ª - 	Preço  e Taxas:
    5.1-	O acesso e uso da Plataforma ITINERE aos Usuários dependerá de pagamento do preço, conforme valor e forma de pagamento especificados e divulgados na Plataforma.
    5.1.1-	O acesso do Usuário à Plataforma do ITINERE será automaticamente bloqueado, sem qualquer aviso ou notificação, caso não seja efetuado o pagamento do preço previsto na Plataforma no momento da adesão do Usuário.
    5.1.2-	Por liberalidade e a seu exclusivo critério, o ITINERE poderá disponibilizar o acesso à Plataforma do ITINERE gratuitamente aos Usuários, por determinado(s) período(s), conforme especificado e divulgado na Plataforma.
    CLÁUSULA 6ª - 	Cuidados do ITINERE com a Plataforma
    6.1-	O ITINERE envidará seus melhores esforços no sentido de manter as informações constantes da Plataforma do ITINERE tão atualizadas, completas e precisas quanto possível. Para esse fim, procurará corrigir o mais rápido possível as eventuais imprecisões ou omissões.
    6.2-	O ITINERE assegurará que as informações fornecidas sejam precisas, completas, atuais e que o seu uso não tenha interrupções nem erros. No entanto, não podemos garantir a operação do Plataforma do ITINERE em tempo integral, na medida em que esta depende de serviços prestados por terceiros, como empresas de telecomunicações e provedores de acesso à internet. Além disso, suspenderemos o uso e acesso à Plataforma em caso fortuito ou força maior, por exemplo, em caso de ataques cibernéticos e outros que possam prejudicar as funcionalidades da Plataforma e colocar em risco as informações dos Usuários.
    CLÁUSULA 7ª - 	PROTEÇÃO DE DADOS PESSOAIS E PRIVACIDADE DOS USUÁRIOS
    7.1-	NO DESENVOLVIMENTO DE QUAISQUER ATIVIDADES RELACIONADAS AO USO E ACESSO À PLATAFORMA DO ITINERE, AS PARTES SE OBRIGAM A OBSERVAR O REGIME LEGAL DA PROTEÇÃO DE DADOS PESSOAIS, EMPENHANDO-SE EM PROCEDER A TODO O TRATAMENTO DE DADOS PESSOAIS QUE VENHA A MOSTRAR-SE NECESSÁRIO AO DESENVOLVIMENTO DO CONTRATO NO ESTRITO E RIGOROSO CUMPRIMENTO DA LEI.
    7.2-	AO ABRIGO DO DISPOSTO NO ITEM ANTERIOR, O ITINERE SE COMPROMETE A:
    a)	TRATAR E USAR OS DADOS PESSOAIS NOS TERMOS LEGALMENTE PERMITIDOS, EM ESPECIAL RECOLHENDO, REGISTRANDO, ORGANIZANDO, CONSERVANDO, CONSULTANDO OU TRANSMITINDO OS MESMOS, APENAS E SOMENTE NOS CASOS EM QUE O SEU TITULAR TENHA DADO O CONSENTIMENTO INEQUÍVOCO OU NOS RESTANTES LEGALMENTE PREVISTOS;
    b)	TRATAR OS DADOS DE MODO COMPATÍVEL COM AS FINALIDADES PARA OS QUAIS TENHAM SIDO RECOLHIDOS;
    c)	CONSERVAR OS DADOS APENAS DURANTE O PERÍODO NECESSÁRIO À PROSSECUÇÃO DAS FINALIDADES DA RECOLHA OU DO TRATAMENTO POSTERIOR, GARANTINDO A SUA CONFIDENCIALIDADE;
    d)	IMPLEMENTAR AS MEDIDAS TÉCNICAS NECESSÁRIAS PARA PROTEGER OS DADOS CONTRA A DESTRUIÇÃO, ACIDENTAL OU ILÍCITA, A PERDA ACIDENTAL, A ALTERAÇÃO, A DIFUSÃO OU O ACESSO NÃO AUTORIZADO, BEM COMO CONTRA QUALQUER OUTRA FORMA DE TRATAMENTO ILÍCITO OU NÃO AUTORIZADO;
    e)	GARANTIR O EXERCÍCIO, PELOS TITULARES, DOS RESPECTIVOS DIREITOS DE INFORMAÇÃO, ACESSO E REVOGAÇÃO;
    f)	ASSEGURAR QUE OS RESPECTIVOS COLABORADORES OU OS PRESTADORES DE SERVIÇOS POR SI CONTRATADOS E QUE VENHAM A TER ACESSO A DADOS PESSOAIS NO CONTEXTO DO CONTRATO CUMPRAM AS DISPOSIÇÕES LEGAIS APLICÁVEIS EM MATÉRIA DE PROTEÇÃO DE DADOS PESSOAIS, DESIGNADAMENTE, NÃO CEDENDO OU DIVULGANDO TAIS DADOS PESSOAIS A TERCEIROS, NEM DELES FAZENDO USO PARA QUAISQUER FINS QUE NÃO OS ESTRITAMENTE CONSENTIDOS PELOS USUÁRIOS OU, SE APLICÁVEL, AQUELES DETERMINADOS PELA LEGISLAÇÃO APLICÁVEL.
    7.3-	O ENCARREGADO PELO TRATAMENTO DE DADOS PESSOAIS ESTARÁ INDICADO NA PLATAFORMA DO ITINERE E PODERÁ SER CONTACTADO PELO USUÁRIO, A QUALQUER TEMPO, PARA ACEITAR RECLAMAÇÕES E COMUNICAÇÕES DOS USUÁRIOS, PRESTAR ESCLARECIMENTOS, ADOTAR PROVIDÊNCIAS E EXECUTAR AS DEMAIS ATRIBUÇÕES LEGAIS.
    7.4-	FAZ PARTE DA POLÍTICA DO ITINERE RESPEITAR A PRIVACIDADE DE SEUS USUÁRIOS. EMBORA O AMBIENTE DA PLATAFORMA DO ITINERE ESTEJA SUJEITO A MONITORAMENTO POR QUESTÕES DE SEGURANÇA,  A ITINERE NÃO IRÁ EDITAR OU DIVULGAR INFORMAÇÕES PRIVATIVAS DE SEUS USUÁRIOS SEM AUTORIZAÇÃO PRÉVIA, EXCETO NOS CASOS EXPRESSAMENTE PREVISTOS NESTE TERMO OU A MENOS QUE O ITINERE SEJA OBRIGADA A FAZÊ-LO MEDIANTE ORDEM JUDICIAL OU POR FORÇA DE LEI.
    7.5-	AO FORNECER OS SEUS DADOS PESSOAIS PARA CADASTRAMENTO E ACESSO À PLATAFORMA DO ITINERE, O USUÁRIO CONSENTE EXPRESSAMENTE, DE FORMA LIVRE, INFORMADA E INEQUÍVOCA, COM O ACESSO E TRATAMENTO DE SEUS DADOS PESSOAIS CUJAS FINALIDADES ESPECÍFICAS DE COLETA SÃO: CADASTRAR E IDENTIFICAR OS USUÁRIOS JUNTO À PLATAFORMA DO ITINERE, INCLUSIVE MEDIANTE MÉTODOS DE AUTENTICAÇÃO; VERIFICAR A AUTENTICIDADE DAS INFORMAÇÕES E DOCUMENTOS FORNECIDOS, INCLUSIVE MEDIANTE TRATAMENTO E VERIFICAÇÃO CRUZADA; INFORMAR AO ITINERE QUEM É O CONTRATANTE; PERMITIR AO ITINERE A VERIFICAÇÃO DO PERFIL DO USUÁRIO PARA FINS DE AVALIAÇÃO E MELHORIAS NA PLATAFORMA DO ITINERE; PERMITIR AO ITINERE A AVALIAÇÃO E ADOÇÃO DE MEDIDAS JULGADAS NECESSÁRIAS OU RECOMENDÁVEIS PELO ITINERE PARA OFERTAR OUTROS SERVIÇOS E/OU FUNCIONALIDADES NA PLATAFORMA DO ITINERE; NOTIFICAR OS USUÁRIOS EM RELAÇÃO AO OBJETO DESTE TERMO; CUMPRIMENTO DE OBRIGAÇÕES LEGAIS E REGULATÓRIAS IMPOSTAS AO ITINERE.
    7.6-	NÓS APENAS IREMOS COLETAR, ARMAZENAR, TRATAR, PROCESSAR E/OU UTILIZAR AS SUAS INFORMAÇÕES PARA AS FINALIDADES DESCRITAS NESTE TERMO.
    7.7-	AS INFORMAÇÕES DE USUÁRIOS COLETADAS QUANDO DO ACESSO OU UTILIZAÇÃO DA PLATAFORMA DO ITINERE E/OU DA CONTRATAÇÃO DOS SERVIÇOS PODERÃO SER TRANSFERIDAS E ARMAZENADAS PELO ITINERE EM UM DESTINO FORA DO BRASIL, NA MEDIDA PERMITIDA POR LEI.  AO ACESSAR E UTILIZAR A PLATAFORMA DO ITINERE E NOS ENVIAR SEUS DADOS PESSOAIS, OS USUÁRIOS CONCORDAM COM A TRANSFERÊNCIA, ARMAZENAMENTO, PROCESSAMENTO E USO DE SUAS INFORMAÇÕES NO FORMATO EXPOSTO NESTE TERMO. A ITINERE OBRIGA-SE A TOMAR TODOS OS CUIDADOS RAZOAVELMENTE NECESSÁRIOS PARA GARANTIR QUE OS SEUS DADOS SEJAM TRATADOS DE MODO SEGURO.
    7.8-	AO ADERIR AO PRESENTE TERMO, O USUÁRIO CONCORDA E CONSENTE TAMBÉM DE FORMA EXPRESSA E INEQUÍVOCA COM A CONCESSÃO DE ACESSO E O COMPARTILHAMENTO DE SUAS INFORMAÇÕES COM O ITINERE E DEMAIS ENTIDADES DO GRUPO ITINERE, BEM COMO COM TERCEIROS SELECIONADOS, CONFORME ELENCADO ABAIXO, ESPECIFICAMENTE PARA QUE SEJAM OBTIDOS OS FINS DESCRITOS NESTA POLÍTICA:
    •	FUNCIONÁRIOS E DEMAIS ENTIDADES MANTIDAS PELO ITINERE, PARCEIROS COMERCIAIS, AGENTES E/OU PRESTADORES DE SERVIÇOS DO ITINERE;
    •	AUTORIDADES GOVERNAMENTAIS E/OU CORTES JUDICIAIS QUE POSSAM NOS OBRIGAR A DIVULGAR, POR LEI OU ORDEM JUDICIAL, A TOTALIDADE OU QUALQUER PARTE DAS INFORMAÇÕES;
    •	OPERADORES DE SISTEMAS DE PAGAMENTO;
    •	QUAISQUER OUTROS TERCEIROS PARA QUEM NÓS SEJAMOS OBRIGADOS A COMPARTILHAR AS INFORMAÇÕES DEVIDO A OBRIGAÇÕES LEGAIS E REGULATÓRIAS E/OU CUJA DIVULGAÇÃO DAS INFORMAÇÕES SEJAM NECESSÁRIAS OU RECOMENDÁVEIS PARA A DEVIDA, EFETIVA E EFICIENTE OPERAÇÃO DA PLATAFORMA DO ITINERE E SUAS FUNCIONALIDADES, BEM COMO PARA A OFERTA DOS SERVIÇOS.
    7.9-	O TÉRMINO DO TRATAMENTO DE DADOS PESSOAIS OCORRERÁ NA HIPÓTESE DE VERIFICAÇÃO DE QUE A FINALIDADE FOI ALCANÇADA OU DE QUE OS DADOS DEIXARAM DE SER NECESSÁRIOS OU PERTINENTES AO ALCANCE DA FINALIDADE ESPECÍFICA ALMEJADA; RESCISÃO DO CONTRATO COM O CONSEQUENTE FIM DO PERÍODO DE TRATAMENTO; A PEDIDO DO USUÁRIO, INCLUSIVE NO EXERCÍCIO DE SEU DIREITO DE REVOGAÇÃO DO CONSENTIMENTO, RESGUARDADO O INTERESSE PÚBLICO; OU POR DETERMINAÇÃO DA AUTORIDADE NACIONAL.
    7.10-	O ITINERE SE RESERVA O DIREITO DE CONSERVAÇÃO DE DADOS PESSOAIS DO USUÁRIO PARA AS SEGUINTES FINALIDADES: I - CUMPRIMENTO DE OBRIGAÇÃO LEGAL OU REGULATÓRIA PELO CONTROLADOR; II - TRANSFERÊNCIA A TERCEIRO, DESDE QUE RESPEITADOS OS REQUISITOS DE TRATAMENTO DE DADOS DISPOSTOS EM LEI; OU III - USO EXCLUSIVO DO ITINERE, VEDADO SEU ACESSO POR TERCEIRO, E DESDE QUE ANONIMIZADOS OS DADOS.
    7.11-	O ITINERE NÃO SOLICITA SENHA, DADOS DE CARTÃO DE CRÉDITO OU OUTROS DADOS BANCÁRIOS DE SEUS USUÁRIOS POR E-MAIL, TELEFONE OU QUALQUER OUTRO CANAL DE ATENDIMENTO PERSONALIZADO. PORTANTO, SE VOCÊ RECEBER QUALQUER COMUNICAÇÃO COM ESSE TIPO DE ABORDAGEM E CONTEÚDO, NÃO RESPONDA, DESCONSIDERE-O E, SE POSSÍVEL, ENCAMINHE O SEU RELATO PARA CONTATO@ITINERE.COM.BR. O ITINERE ESTÁ ATIVO NO COMBATE À FRAUDE ELETRÔNICA E CONTA COM A CONSCIENTIZAÇÃO DE SEUS USUÁRIOS PARA A PRÁTICA DA NAVEGAÇÃO SEGURA NA INTERNET.
    7.12-	Caso você queira revogar o consentimento para tratamento e arquivamento das suas informações pessoais, pedimos que, por favor, entre em contato com nossa central de atendimento ou escreva um e-mail o quanto antes para nossa equipe no endereço: contato@ITINERE.com.br. Em caso de revogação de consentimento, o acesso à Plataforma do ITINERE será automaticamente encerrado e o presente contrato estará automaticamente rescindido.
    CLÁUSULA 1ª - 	Plataformas e informações disponibilizados por terceiros.
    1.1-	 O ITINERE pode, a seu exclusivo critério, disponibilizar links para plataformas e páginas de terceiros. Essas plataformas e/ou páginas não foram, necessariamente, revisadas pelo ITINERE e são mantidas por terceiros sobre os quais o ITINERE não exerce qualquer tipo de controle ou ingerência. O uso dessas plataformas e/ou páginas e de suas funcionalidades, bem como a contratação de qualquer serviço ou produto em plataforma(s) de terceiros, são de sua total responsabilidade e você deve atentar-se aos termos e condições de uso e política de privacidade de cada uma dessas plataformas.
    1.2-	Em nenhuma hipótese o ITINERE será responsável por links acessados pelos Usuários a partir da Plataforma do ITINERE, bem como por atos praticados por Usuário que tenham por base informações obtidas nesses links. O ITINERE não se responsabiliza nem pelo conteúdo, nem pelas políticas e práticas de privacidade dessas plataformas e/ou páginas de terceiros, nem por quaisquer políticas e práticas de terceiros que direcionam os Usuários para a Plataforma do ITINERE.
    CLÁUSULA 2ª - 	Proibições: É vedado ao Usuário, dentre outras coisas:
    (a)	violar qualquer legislação municipal, nacional ou internacional que seja integrada ao ordenamento jurídico brasileiro, ou ainda, que, por qualquer razão legal, deva ser no Brasil aplicada;
    (b)	praticar atos contrários à moral e aos bons costumes;
    (c)	assumir a personalidade ou identidade de outra pessoa, física ou jurídica;
    (d)	carregar, transmitir, divulgar, exibir, enviar, ou de qualquer outra forma tornar disponível qualquer conteúdo que seja ilegal, incluindo, mas sem se limitar a, conteúdo que seja ofensivo à honra e à privacidade de terceiros, pornográfico, obsceno, eleitoral, difamatório ou calunioso, vulgar, preconceituoso, racista, discriminatório, que faça apologia ao crime ou de qualquer forma censurável, ou que possa gerar qualquer responsabilidade civil ou criminal de acordo com a Lei;
    (e)	carregar, transmitir, divulgar, exibir, enviar, ou de qualquer forma tornar disponível qualquer conteúdo que viole quaisquer direitos de terceiro, incluindo Direitos de Propriedade Intelectual (conforme definido abaixo);
    (f)	carregar, transmitir, divulgar, exibir, enviar, ou de qualquer forma tornar disponível qualquer tipo de anúncio, propaganda ou material promocional não solicitado ou não autorizado pelo ITINERE, tais como mensagens publicitárias, mensagens não solicitadas ou mensagens enviadas em massa (conhecidos como "junk mail", "spam" ou outros);
    (g)	carregar, transmitir, divulgar, exibir, enviar, ou de qualquer forma tornar disponível qualquer conteúdo que contenha vírus ou qualquer outro código, arquivo ou programa de computador com o propósito de interromper, destruir ou limitar a funcionalidade de qualquer software, hardware ou equipamento;
    (g)	ameaçar, coagir, ou constranger demais Usuários;
    (h)	violar direitos de sigilo e privacidade de terceiros; e
    (i)	praticar quaisquer atos que direta ou indiretamente, no todo ou em parte, possam causar prejuízo ao ITINERE, a outros Usuários ou a qualquer terceiro.
    CLÁUSULA 3ª - 	Conteúdo gerado pelo Usuário. Qualquer material, informação ou ideia que você transmitir, publicar ou de qualquer forma disponibilizar na Plataforma do ITINERE – exceto dados pessoais ou aquelas tratadas pela Lei como sigilosas, ou com identificação de fonte –, será tratado como não-conﬁdencial e não-proprietário, podendo ser divulgado ou utilizado pelo ITINERE para qualquer propósito.
    CLÁUSULA 4ª - 	Propriedade intelectual: A Plataforma do ITINERE contém ou pode conter nome de domínio, textos, fotograﬁas, imagens, vídeos, marcas, logomarcas, layouts, código-fonte, gráficos, know-how, som entre outros elementos e materiais que se encontram protegidos por direitos de propriedade industrial e direitos autorais (“Direitos de Propriedade Intelectual”). Todos os Direitos de Propriedade Intelectual aqui presentes pertencem ao ITINERE ou foram outorgados ao ITINERE, de tal forma que apenas o ITINERE possui o direito de usar, explorar, reproduzir, comercializar ou licenciar a terceiros todo o material disponibilizado na Plataforma do ITINERE, bem como as funcionalidades e programas da própria Plataforma.
    4.1-	Ao acessar a Plataforma do ITINERE o Usuário declara e se compromete a respeitar os Direitos de Propriedade Intelectual do ITINERE ou a ela outorgados, bem como de todos os direitos referentes a terceiros que por ventura estejam, ou estiveram, de alguma forma, disponíveis na Plataforma. O acesso à Plataforma do ITINERE não confere ao Usuário qualquer Direito de Propriedade Intelectual, seja sobre a Plataforma, seja sobre todo e qualquer conteúdo veiculado ou disponibilizado através dela.
    4.2-	A reprodução de qualquer conteúdo da Plataforma do ITINERE é permitida tão somente conforme condições descritas neste Termo, sendo proibida qualquer reprodução, disponibilização, divulgação ou uso diverso do previsto nestes Termos. Em nenhum caso o Usuário adquirirá qualquer Direito de Propriedade Intelectual sobre os conteúdos disponibilizados na Plataforma do ITINERE.
    4.3-	Em caso de violação pelo Usuário de qualquer Direito de Propriedade Intelectual do ITINERE ou de terceiros, o Usuário assume toda e qualquer responsabilidade, de caráter civil e/ou criminal advindos de referida violação.
    4.4-	A ITINERE acredita na eficácia do sistema de proteção à propriedade intelectual e se preocupa em respeitar a propriedade intelectual alheia. Se, por qualquer motivo, você entender que algum direito seu está sendo infringido pelos textos, artigos, vídeos, notícias, fotograﬁas, leiautes ou qualquer outro conteúdo reproduzido na Plataforma do ITINERE, pedimos que nos envie uma mensagem detalhada para contato@ITINERE.com.br, para que possamos responder-lhe diretamente e resolvermos a situação. A mensagem deverá conter: a) assinatura eletrônica ou fíITINEREa da pessoa autorizada a agir em nome do proprietário dos direitos; (b) descrição da marca ou obra protegida por direitos autorais que alega ter sido infringida; (c) o endereço virtual (URL) em que se encontra o material que alega apresentar infração ao seu direito; (d) seu endereço, número de telefone e e-mail para contato; (e) declaração de que você tem boas razões para acreditar que o uso contestado não é autorizado pelo proprietário dos direitos ou pela lei; e (f) declaração feita por você de que as informações em sua notificação são precisas e de que você é o proprietário dos direitos ou autorizado a atuar em nome do proprietário dos direitos.
    4.4.1-	A eventual retirada de qualquer conteúdo da Plataforma do ITINERE em decorrência de alguma reclamação, deverá ser sempre compreendida como uma demonstração de nossa intenção de evitar pendências com relação a esse assunto e, jamais, como reconhecimento de qualquer infração a direito de terceiro.
    4.5-	Para a segurança do Usuário, faz parte da Política de Segurança da ITINERE não aceitar ou considerar ideias, sugestões ou materiais criativos, incluindo, entre outros, anotações, desenhos, conceitos, comentários, sugestões de técnicas, informações sobre know-how, material publicitário, de vídeo ou outras informações (“Envios”) diferentes daqueles especificamente solicitados para o cadastro do Usuário na Plataforma d ITINERE. A intenção desta Política é evitar a possibilidade de futuros mal-entendidos quando projetos desenvolvidos pelos profissionais da ITINERE forem semelhantes aos trabalhos criativos de terceiros que tenham realizado qualquer envio à ITINERE. TODOS OS ENVIOS DE MATERIAL PELO USUÁRIO SERÃO TRATADOS COMO NÃO-CONFIDENCIAIS E NÃO-PROPRIETÁRIOS, PODENDO A ITINERE USAR, USUFRUIR E DISPOR DA FORMA QUE ENTENDER SOBRE TAIS ENVIOS. A ITINERE NÃO SE RESPONSABILIZARÁ PELO USO OU DIVULGAÇÃO DE QUAISQUER ENVIOS E, DESDE JÁ, VOCÊ RECONHECE QUE NADA LHE SERÁ DEVIDO POR CONTA DO USO OU DIVULGAÇÃO DO MATERIAL ENVIADO.
    CLÁUSULA 5ª - 	Restrições e Problemas de Acesso: Os serviços disponibilizados através da Plataforma do ITINERE estão sujeitos a interrupções, atrasos e problemas inerentes ao uso da Internet. A ITINERE não será responsável por qualquer dessas interrupções, atrasos ou problemas, nem por eventuais defeitos ou limitações dos equipamentos ou do programa de navegação utilizados pelos Usuários ou, ainda, por eventuais defeitos ou limitações dos serviços de provimento de acesso ou infraestrutura de acesso à Internet contratados pelos Usuários.
    5.1-	A ITINERE também não se responsabiliza por qualquer vírus que possa atacar o dispositivo do Usuário em decorrência do acesso, utilização ou navegação na Internet ou como consequência da transferência de dados, arquivos, imagens, textos ou áudio.
    CLÁUSULA 6ª - 	Prazo: Estes Termos permanecerão em pleno vigor e efeito enquanto: (a) os serviços forem disponibilizados na Plataforma do ITINERE e não forem substituídos por outros termos de uso; (b) enquanto o Usuário estiver acessando a Plataforma do ITINERE; e (c) enquanto o Usuário estiver cadastrado na Plataforma do ITINERE.
    CLÁUSULA 7ª - 	Se você é um Usuário Cadastrado, você pode solicitar o cancelamento do acesso à Plataforma do ITINERE e encerrar a sua participação a qualquer momento, independentemente do motivo através da ferramenta disponível no seu perfil de Usuário, ou enviando a solicitação pelo e-mail contato@ITINERE.com.br. Após o envio do pedido pelo Usuário, a ITINERE poderá confirmar o recebimento da solicitação em até 10 (dez) dias úteis. O encerramento da Conta do Usuário será efetuado no mês imediatamente seguinte ao mês correspondente à data da confirmação da remoção.
    7.1-	Nós poderemos, a nosso exclusivo critério, cancelar qualquer conta e remover qualquer serviço ou conteúdo disponibilizado na Plataforma do ITINERE, a qualquer momento e por qualquer motivo, sem que isso gere ao Usuário qualquer direito de indenização.
    CLÁUSULA 8ª - 	Indenização: Você concorda em isentar e indenizar, defender e manter a ITINERE e seus respectivos diretores, agentes, sócios e funcionários indenes de qualquer prejuízo, responsabilidade, ação judicial ou demanda, incluindo honorários advocatícios, devidos a ou decorrentes do uso da Plataforma do ITINERE ou de violação dos presentes Termos e Condições de Uso ou da Política de Privacidade.
    8.1-	Você concorda, ainda, em isentar e indenizar, defender e manter a ITINERE e seus respectivos diretores, agentes, sócios e funcionários indenes de qualquer prejuízo, responsabilidade, ação judicial ou demanda, incluindo honorários advocatícios, devidos a ou decorrentes de falhas ocorridas nos dispositivos do Usuário, assim como da utilização indevida das informações ou materiais disponibilizados na Plataforma do ITINERE.
    CLÁUSULA 9ª - 	Notificações: Todas as notiﬁcações a quaisquer das Partes devem ser feitas por escrito, via correio convencional ou eletrônico (e-mail). A ITINERE poderá divulgar notiﬁcações ou mensagens de alerta por meio da Plataforma do ITINERE para informar o Usuário sobre mudanças no acesso à Plataforma, nestes Termos e Condições de Uso ou na Política de Privacidade, entre outros assuntos relevantes.
    9.1-	O Usuário ao aceitar estes Termos e Condições de Uso consente receber comunicados por meios eletrônicos relacionados à sua conta, incluindo o envio de e-mails para o endereço de e-mail informado durante a inscrição, a publicação de comunicados na Plataforma do ITINERE, e poderá incluir avisos relacionados à sua conta (autorizações de pagamento, mudanças de senha ou forma de pagamento, confirmações e outras informações relativas a transações). Tal comunicação constituirá parte do seu relacionamento com a Plataforma do ITINERE. Você concorda que quaisquer avisos, contratos, divulgações ou outras comunicações enviadas pela Plataforma do ITINERE para você eletronicamente e/ou através de quaisquer meios, tais como telefonia celular, SMS, WHATSAPP, TELEGRAM, SKYPE, correio eletrônico, bem como via SAC, satisfazem os requisitos legais de comunicação, incluindo o requisito de que tais comunicações sejam por escrito. Você também concorda em receber outras comunicações da Plataforma do ITINERE, como boletins informativos sobre novas funcionalidades e conteúdo da Plataforma do ITINERE, ofertas especiais, promoções e pesquisas de mercado, seja por e-mail ou outros meios de comunicação. Se você não quiser mais receber determinados comunicados não transacionais da Plataforma do ITINERE, entre em contato pelo e-mail contato@ITINERE.com.br.
    CLÁUSULA 10ª - 	Lei aplicável e jurisdição: Os presentes Termos e Condições de Uso são interpretados de acordo com as leis da República Federativa do Brasil. Fica eleito o Foro da Comarca de Belo Horizonte, Estado de Minas Gerais, para dirimir qualquer divergência oriunda dos presentes Termos de Uso, com renúncia expressa a qualquer outro, por mais privilegiado que seja.
    CLÁUSULA 11ª - 	Disposições gerais:
    11.1-	Se qualquer disposição destes Termos e Condições de Uso tornar-se inválida ou inexequível, tal disposição será anulada e as demais disposições serão mantidas.
    11.2-	Os títulos são apenas para efeitos de referência e de forma alguma definem, limitam, determinam a interpretação ou descrevem o âmbito ou extensão da respectiva seção.
    11.3-	A omissão do ITINERE com relação a qualquer falha do Usuário ou de outros em cumprir com estes Termos e Condições de Uso não significa renúncia dos direitos do ITINERE de agir em relação às falhas subsequentes ou similares.
    11.4-	Caso tenha qualquer dúvida em relação aos presentes Termos e Condições de Uso, favor entrar em contato com o ITINERE, por meio do e-mail contato@ITINERE.com.br ou pelo telefone _________.
    Eu li e aceito estes Termos e Condições de Uso
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}