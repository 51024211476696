import React, { Component } from 'react';

export default class Footer extends Component {
    constructor() {
        super();
    }

    componentDidMount() {
    }

    render() {
        return (
            <footer className="app-footer">
                <div className="container">
                    <div className="footer-container">
                        <div className="footer-left">
                            <div className="footer-logo"></div>
                            <div className="footer-menu">
                                <a href="#" className="menu-footer-link w-inline-block">
                                    <div>Sobre o Itinere</div>
                                </a>
                                <a href="mailto:contato@itinere.com.br" className="menu-footer-link w-inline-block">
                                    <div>Fale Conosco</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}