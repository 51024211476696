import React, { Component } from 'react';
import Iugu from './.././../../utils/iuguService';
import Inputmask from "inputmask";
import { createPaymentToken } from '../../../utils/api';
import { connect } from 'react-redux';
import {
    withRouter
} from 'react-router-dom';
import { setUser, setUid } from '../../../reducers/user';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';

class ChangeCreditCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saving: false,
            validating: false
        };
    }
    iugu = Iugu;

    componentDidMount() {
        var credit = document.getElementById("credit");
        Inputmask({ "mask": "9999 9999 9999 9999" }).mask(credit);

        var cvv = document.getElementById("cvv");
        Inputmask({ "mask": "999" }).mask(cvv);

        var valid = document.getElementById("valid");
        Inputmask({ "mask": "99/99" }).mask(valid);
    }

    signItinere = async (e) => {
        e.preventDefault();

        if (!Iugu.utils.validateCreditCardNumber(document.getElementById('credit').value)) {
            this.setState({ invalidCreditCard: true });
            return;
        } else
            this.setState({ invalidCreditCard: false });

        if (!Iugu.utils.validateExpirationString(document.getElementById('valid').value)) {
            this.setState({ invalidValid: true });
            return;
        } else
            this.setState({ invalidValid: false });

        const cardBrand = Iugu.utils.getBrandByCreditCardNumber(document.getElementById('credit').value);

        if (!Iugu.utils.validateCVV(document.getElementById('cvv').value, cardBrand)) {
            this.setState({ invalidCvv: true });
            return;
        } else
            this.setState({ invalidCvv: false });

        let newToken = {}
        newToken.creditCard = document.getElementById('credit').value;
        newToken.cvv = document.getElementById('cvv').value;

        const name = document.getElementById('name').value
        const arrayName = name.split(' ').filter((partName, index) => (index > 0));
        let lastName = "";
        arrayName.forEach((name) => {
            lastName += " " + name;
        });

        newToken.firstName = name.split(' ')[0];
        newToken.lastName = lastName;

        const valid = document.getElementById('valid').value;
        newToken.month = valid.split('/')[0];
        newToken.year = valid.split('/')[1];

        this.setState({ saving: true });


        createPaymentToken(this.props.user.uid, newToken)
            .then((result) => {
                if (result.user) {
                    this.props.setUser(result.user);
                }
                ToastsStore.success("Cartão atualizado com sucesso !");
                setTimeout(() => {
                    document.location.reload();
                }, 800);
            })
            .catch(() => {
                this.setState({ saving: false });
                ToastsStore.error("Não foi possível atualizar o cartão, favor conferir os dados !");
            });
    }

    handleBlurCreditCard = (e) => {
        e.preventDefault();

        if (!Iugu.utils.validateCreditCardNumber(document.getElementById('credit').value)) {
            this.setState({ invalidCreditCard: true });
            return;
        } else
            this.setState({ invalidCreditCard: false });
    }

    handleBlurValid = (e) => {
        e.preventDefault();

        if (!Iugu.utils.validateExpirationString(document.getElementById('valid').value)) {
            this.setState({ invalidValid: true });
            return;
        } else
            this.setState({ invalidValid: false });
    }

    handleBlurCvv = (e) => {
        e.preventDefault();

        const cardBrand = Iugu.utils.getBrandByCreditCardNumber(document.getElementById('credit').value);

        if (!Iugu.utils.validateCVV(document.getElementById('cvv').value, cardBrand)) {
            this.setState({ invalidCvv: true });
            return;
        } else
            this.setState({ invalidCvv: false });
    }

    render() {
        let { saving, validating } = this.state;
        return (

            <div className="portlet-modal">
                <form id="step-two-form" name="step-two-form" onSubmit={this.signItinere}>
                    <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
                    <div className="portlet-modal-header">
                        <div className="portlet-modal-title">Troca de cartão</div>
                        <div className="portlet-modal-close" style={{ cursor: "pointer" }} onClick={this.props.closeModal}></div>
                    </div>
                    <div className="portlet-modal-main">
                        <div className="signup-middle-container">
                            <div className="signup-middle-right">
                                <div>Digite os dados do novo cartão</div>

                                <div className="signup-form w-form">
                                    <input type="text" className="input w-input" maxLength="256" name="credit" placeholder="Número do Cartão" id="credit" onBlur={this.handleBlurCreditCard} required />
                                    {
                                        this.state.invalidCreditCard &&
                                        <div className="error-message">
                                            <div className="text-block"><strong>Cartão inválido!</strong> <br />Por favor confira e tente novamente.</div>
                                        </div>
                                    }
                                    <input type="text" className="input w-input" maxLength="256" name="name" placeholder="Nome impresso no cartão" id="name" required />
                                    <div className="signup-two-columns">
                                        <input type="text" className="input vencimento w-input" maxLength="256" name="valid" placeholder="Vencimento" id="valid" required onBlur={this.handleBlurValid} />
                                        <input type="text" className="input cvv w-input" maxLength="256" name="cvv" placeholder="CVV" id="cvv" required onBlur={this.handleBlurCvv} />
                                    </div>
                                    {
                                        this.state.invalidValid &&
                                        <div className="error-message">
                                            <div className="text-block"><strong>Data inválida!</strong> <br />Por favor confira a data de vencimento e tente novamente.</div>
                                        </div>
                                    }
                                    {
                                        this.state.invalidCvv &&
                                        <div className="error-message">
                                            <div className="text-block"><strong>CVV inválido!</strong> <br />Por favor confira o CVV e tente novamente.</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portlet-modal-footer">
                        <input type="submit" value={saving ? "" : (validating ? "Validando cupom..." : "Trocar Cartão")} className={saving ? "btn btn-login w-button btn-loading" : "btn btn-login w-button"} disabled={saving || validating} />
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state, { navigation }) {
    return {
        user: state.user.user
    }
}

const mapDispatchToProps = dispatch => ({
    setUser: user => dispatch(setUser(user)),
    setUid: uid => dispatch(setUid(uid))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ChangeCreditCard))