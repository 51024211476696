import React, { Component } from 'react';
import ReactGA from 'react-ga';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import NotFoundCore from '../../components/notFound/NotFoundCore';
import { connect } from 'react-redux';
import {
    withRouter
} from 'react-router-dom';

class NotFound extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 200);
    }

    render() {
        return (
            <div>
                {
                    this.props.user && this.props.user.uid ?
                        <div>
                            <Header breads={[{ name: 'Trilhas', link: '/dashboard' }, { name: 'Filhos 2.1' }]} />
                            <NotFoundCore />
                            <Footer />
                        </div>
                        :
                        <div>
                            <div className="login-left">
                                <div className="login-left-header">
                                    <a href="http://www.itinere.com.br" className="w-inline-block">
                                        <img src={require('../../assets/images/itinere-logo.png')} alt="" /></a>
                                </div>
                                <div className="login-left-middle">
                                    <NotFoundCore />
                                </div>
                            </div>
                        </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state, { navigation }) {
    return {
        user: state.user.user
    }
}

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(NotFound))