import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    withRouter
  } from 'react-router-dom';

class LoginForm extends Component {
    constructor() {
        super();
    }

    componentDidMount() {
    }

    toPath = (e) => {
        e.preventDefault();

        this.props.history.push(e.currentTarget.id);
    }

    render() {
        return (
            <div className="login-form">
                <img src={require('../../assets/images/itinere-white.svg')} alt="" className="login-right-logo-white" />
                <img src={require('../../assets/images/itinere-logo.png')} alt="" className="login-right-logo" />
                <h1 className="login-heading">Acessar <br /><span className="login-heading-light">Minha Conta</span></h1>
                <div className="login-form-block w-form">
                    <form id="email-form" name="email-form" data-name="Email Form" onSubmit={this.props.signIn.bind(this)}>
                        <input type="email" className="login-input-text w-input" autoFocus={true} maxLength="256" name="email" data-name="Email" placeholder="E-mail" id="email" required />
                        <input type="password" className="login-input-text w-input" maxLength="256" name="email" data-name="Senha" placeholder="Senha" id="senha" required />
                        <input type="submit" value={this.props.loading ? " " : "Acessar minha conta"} className={this.props.loading ? "btn btn-login w-button btn-loading" : "btn btn-login w-button"} />
                    </form>
                    {
                        this.props.invalidLogin &&
                        <div className="error-message bg-color">
                            <div className="text-block"><strong>Login inválido!</strong> <br />Por favor confira os dados e tente novamente.</div>
                        </div>
                    }
                </div>
                <a href="/esqueci-minha-senha" id="/esqueci-minha-senha" onClick={this.toPath} className="login-forgot">Esqueci minha senha</a>
            </div>
        );
    }
}


function mapStateToProps(state, { navigation }) {
    return {
        invalidLogin: state.user.invalidLogin
    }
}

export default connect(
    mapStateToProps,
    null
)(withRouter(LoginForm))