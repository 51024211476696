import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    withRouter
} from 'react-router-dom';
import { sendReply, getPostByCode } from '../../utils/api';
import Modal from '../../components/modal/Modal';

class ForumDetailCore extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            post: this.props.location && this.props.location.state ? this.props.location.state.post : this.getPost()
        }
    }

    componentDidMount() {
        this.getPost();
    }

    getPost = () => {
        this.setState({ loading: true });
        getPostByCode(this.props.match.params.postId)
            .then((result) => {
                this.setState({ loading: false, post: result.post });
            })
            .catch((error) => {
                this.setState({ loading: false });
            });
    }

    doReply = (e) => {
        e.preventDefault();

        const reply = {
            description: document.getElementById('description').value,
            postCode: this.post.code,
            userCpf: this.props.user.cpf
        }

        this.setState({ loading: true });
        sendReply(reply)
            .then(() => {
                this.getPost();
            })
            .catch((error) => {
                this.setState({ loading: false });
            });
    }

    getPostTimeAgo = () => {
        const post = this.props.location && this.props.location.state ? this.props.location.state.post : this.getPost();
        const year = post.date.split('-')[0];
        const month = parseInt(post.date.split('-')[1]) - 1;
        const day = post.date.split('-')[2];
        const date = new Date(year, month, day);
        const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

        const diffTime = Math.abs(date - today);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays === 0)
            return `hoje`
        else if (diffDays === 1)
            return `ontem`
        else
            return `há ${diffDays} dia(s)`
    }

    getReplyTimeAgo = (reply) => {
        const year = reply.date.split('-')[0];
        const month = parseInt(reply.date.split('-')[1]) - 1;
        const day = reply.date.split('-')[2];
        const date = new Date(year, month, day);
        const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

        const diffTime = Math.abs(date - today);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays === 0)
            return `hoje`
        else if (diffDays === 1)
            return `ontem`
        else
            return `há ${diffDays} dia(s)`
    }

    render() {
        const { post } = this.state;
        this.post = post;

        if (this.state.loading)
            return (
                <div style={{ display: "flex", alignItems: "center", width: "100%", height: "500px", justifyContent: "center" }}>
                    <img src={require('../../assets/images/rolling.svg')} alt="" width="30px" height="30px" />
                </div>
            );

        return (
            <main className="forum">
                <div className="container">
                    <div className="forum-post-detail-container">
                        <div className="forum-post-tag" style={{ backgroundColor: post.course.color }}>{post.course.name}</div>
                        <div className="forum-post-detail-two-columns" style={{ width: '100%' }}>
                            <h1 className="forum-post-detail-title" style={{ flexGrow: 5 }}>{post.title}</h1>
                            <div className="forum-post-author">
                                <div className="forum-post-photo" style={{
                                    backgroundImage: 'url(' + (post.user.photoURL !== undefined ? post.user.photoURL : require('../../assets/images/avatar.png')) + ')'
                                }}></div>
                                <div className="forum-post-info">
                                    <div className="forum-post-author-name">{post.user.displayName}</div>
                                    <div className="forum-post-author-since">{this.getPostTimeAgo()}</div>
                                    {
                                        (this.props.user.isModerator || this.props.user.cpf === post.userCpf) &&
                                        <Modal idModal={post.code} buttonClass="delete-post" buttonDesc="Apagar Post" type={"deletePost"} post={post} />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="forum-post-detail-content"><p style={{ whiteSpace: 'pre-wrap' }}>{post.description}</p></div>
                    </div>
                </div>
                <div className="forum-post-detail-replies">
                    <div className="container">
                        <div className="forum-post-detail-replies-container">
                            <div className="forum-post-detail-no-replies">{post.replies.length === 0 ? 'Nenhuma resposta até o momento' : `${post.replies.length} Resposta(s)`}</div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    {
                        post.replies.length > 0 && post.replies.map((reply, index) => {
                            return (
                                <div className="post-reply" key={index} style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)', marginBottom: '10px' }}>
                                    <div className="reply-details">
                                        <div className="forum-post-author">
                                            <div className="forum-post-photo" style={{
                                                backgroundImage: 'url(' + (reply.user.photoURL !== undefined ? reply.user.photoURL : require('../../assets/images/avatar.png')) + ')'
                                            }}></div>
                                            <div className="forum-post-info">
                                                <div className="forum-post-author-name">{reply.user.displayName}</div>
                                                <div className="forum-post-author-since">{this.getReplyTimeAgo(reply)}</div>
                                                {
                                                    (this.props.user.isModerator || this.props.user.cpf === reply.user.cpf) &&
                                                    <Modal idModal={reply.code} buttonClass="delete-post" buttonDesc="Apagar Resposta" type={"deleteReply"} reply={reply} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="reply-description">
                                        <br />
                                        <p style={{ whiteSpace: 'pre-wrap' }}>{reply.description}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="container">
                    <div className="w-form">
                        <form id="email-form" name="email-form" onSubmit={this.doReply} className="forum-post-detail-form">
                            <textarea placeholder="Participe da conversa escrevendo sua resposta aqui" maxLength="5000" id="description" name="description"
                                className="input forum-new-post-textarea w-input" required></textarea>
                            <input type="submit" value="Postar resposta" data-wait="Please wait..." className="btn btn-medium w-button" />
                        </form>
                    </div>
                </div>
            </main >
        );
    }
}

function mapStateToProps(state, { navigation }) {
    return {
        user: state.user.user
    }
}

export default connect(
    mapStateToProps,
    null
)(withRouter(ForumDetailCore))