import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from '../../utils/api';
import { setUser, setUid } from '../../reducers/user';
import {
    withRouter
} from 'react-router-dom';
import './Header.css';

class Header extends Component {
    constructor() {
        super();

        this.state = {
            openMenu: false
        }
    }

    componentDidMount() {
    }

    signOut = (e) => {
        e.preventDefault();

        logout()
            .then(() => {
                this.props.history.push('/');
                this.props.setUser({});
                this.props.setUid({});
            });
    }

    toPath = (e) => {
        e.preventDefault();

        this.props.history.push(e.currentTarget.id);
    }

    handleMenu = (e) => {
        e.preventDefault();

        const { openMenu } = this.state;

        this.setState({
            openMenu: !openMenu
        });
    }

    goToRegisterSecoundStep = (e) => {
        e.preventDefault();
        this.props.history.push('/register', { step: 2 });
    }

    render() {
        const isMobile = document.documentElement.clientWidth < 991;
        const { openMenu } = this.state;
        const { user } = this.props;

        return (
            <header className="app-header">
                <div className="container">
                    <div className="header-container">
                        <div className="header-nav">
                            <nav className="nav">
                                <div className="menu-icon"></div>
                                <img src={require('../../assets/images/itinere-logo.png')} alt="" className="image" id="/dashboard" onClick={this.toPath.bind(this)} style={{ cursor: "pointer" }} />
                                <ul className="nav-list">
                                    <li className="nav-list-item">
                                        <a href="/dashboard" className="nav-link w-inline-block" id="/dashboard" onClick={this.toPath.bind(this)}>
                                            <div>Início</div>
                                        </a>
                                    </li>
                                    <li className="nav-list-item" data-ix="trilhas-hover">
                                        <a href className="nav-link w-inline-block" style={{ cursor: 'pointer' }}>
                                            <div>Trilhas de Aprendizagem</div>
                                        </a>
                                        <ul className="subnav-list" style={{ display: openMenu ? 'block' : '', opacity: openMenu ? '1' : '' }}>
                                            <li>
                                                <a href="/course/filhos21" className="subnav-liink w-inline-block" id="/course/filhos21" onClick={this.toPath.bind(this)}>
                                                    <div>Filhos 2.1</div>
                                                </a>
                                            </li>
                                            <li>
                                                <a className="subnav-liink w-inline-block">
                                                    <div>Comunicação saudável <span className="menu-item-small">(em breve)</span></div>
                                                </a>
                                            </li>
                                            <li>
                                                <a className="subnav-liink w-inline-block">
                                                    <div>Produtividade na mira <span className="menu-item-small">(em breve)</span></div>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    {
                                        this.props.user.plan === 'none' &&
                                        <li className="nav-list-item">
                                            <a href="" className="nav-link w-inline-block" onClick={this.goToRegisterSecoundStep}>
                                                <div>Assine o Itinere</div>
                                            </a>
                                        </li>
                                    }
                                    <li className="nav-list-item">
                                        <a href="/forum" className="nav-link w-inline-block" id="/forum" onClick={this.toPath.bind(this)}>
                                            <div className="menu-icon-forum"></div>
                                            <div>Fórum</div>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="header-user">
                            <div className="header-user-info">
                                <div id="/mydata" style={{ cursor: "pointer" }} className="header-user-name" onClick={this.toPath.bind(this)}>Olá, <span className="header-user-firstname">{user.displayName ? user.displayName.split(' ')[0] : ''}</span></div>
                                <div className="header-user-name">
                                    <a href="/mydata" id="/mydata" className="header-user-signout" onClick={this.toPath.bind(this)}>Meus Dados</a>
                                    <a href="" className="header-user-signout" onClick={this.signOut.bind(this)}> | Sair</a>
                                </div>
                            </div>
                            <div className="header-user-photo"
                                style={{
                                    backgroundImage: 'url(' + (user.photoURL !== undefined ? user.photoURL : require('../../assets/images/avatar.png')) + ')',
                                    cursor: 'pointer'
                                }}
                                id="/mydata" onClick={this.toPath.bind(this)}></div>
                        </div>
                    </div>
                </div>
                <div className="breadcrumbs">
                    <div className="container">
                        <div className="bread-holder">
                            <a href="/dashboard" className="bread-home" id="/dashboard" onClick={this.toPath.bind(this)}>Início</a>
                            <div className="bread-icon"></div>
                            {
                                this.props.breads && this.props.breads.map((bread, index) => {
                                    return (
                                        <div className={index === 0 ? "bread-holder" : "bread-holder bread-holder-loop"} key={index}>
                                            <div className="bread-separator"></div>
                                            <a href={bread.link ? bread.link : undefined} id={bread.link ? bread.link : index}
                                                onClick={bread.link ? this.toPath.bind(this) : () => { }} className={bread.link ? "bread-home" : "bread-link"}>
                                                {bread.name}
                                            </a>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

function mapStateToProps(state, { navigation }) {
    return {
        user: state.user.user
    }
}

const mapDispatchToProps = dispatch => ({
    setUser: user => dispatch(setUser(user)),
    setUid: uid => dispatch(setUid(uid))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Header))