export const SET_CHAPTER = 'SET_CHAPTER';
export const REMOVE_CHAPTERS = 'REMOVE_CHAPTERS';

export function actionSetChapter(chapter) {
    return {
        type: SET_CHAPTER,
        chapter
    }
}

export function actionRemoveChapters() {
    return {
        type: REMOVE_CHAPTERS
    }
}