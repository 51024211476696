import React, { Component } from 'react';
import '../Modal.css'

export default class Depoiment extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="container">
                <div className="portlet-modal-close" style={{ cursor: "pointer" }} onClick={this.props.closeModal}></div>
                <iframe src="https://player.vimeo.com/video/324848911" width="840" height="560" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
            </div>
        );
    }
}